import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, Point, SolutionPoint } from "../../components/points";
const Services = () => {
  const services = [


    {
      name: "Provide telemedicine services ",
      text: "to clients and new clients signup",
      details:
        "Provide first line consultancy to your exisiting ",
      details2: "clients via voice, video and messaging.",
      details3: "  methods. Your offered health plans visible to",
      details4: "potential clients and seamless signup through the mobile app.",
      icon: Circles,
    },


    {
      name: "Service provider",
      text: "integration",
      details:
        "Integration with your affiliated hospitals to ",
      details2: " allow for easy consultation access and ",
      details3: " billing.",
      icon: RoundedSquares,
    },
    {
      name: "Setup patient",
      text: "and payment charges",
      details: `Pre-authorization and complaints `,
      details2: "management for your clients who need to ",
      details3: "access medical services on their health",
      details4: "  plans.",
      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2 ml-n2 pt-3">
      <div className="w-100 mb-4">
        <SolutionPoint
          title="Health Insurance Providers  "
          body="The  platform provides health Insurance providers the tools to manage remote consultancy and pre-authorization for their existing clients and offer insurance plans to new subscribers. "
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5 ml-n3">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4  ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              body={item.details}
              subtitle={item.text}
              body2={item.details2}
              body3={item.details3}
              body4={item.details4}
              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
