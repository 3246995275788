import React from "react";
const Chinese = ({ width, height, fill, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <g clip-path="url(#clip0_510_1229)">
        <path d="M7.53906 5V2C7.53906 1.73478 7.64442 1.48043 7.83196 1.29289C8.01949 1.10536 8.27385 1 8.53906 1H16.5391C16.8043 1 17.0586 1.10536 17.2462 1.29289C17.4337 1.48043 17.5391 1.73478 17.5391 2V5H21.5391C21.8043 5 22.0586 5.10536 22.2462 5.29289C22.4337 5.48043 22.5391 5.73478 22.5391 6V20C22.5391 20.2652 22.4337 20.5196 22.2462 20.7071C22.0586 20.8946 21.8043 21 21.5391 21H3.53906C3.27385 21 3.01949 20.8946 2.83196 20.7071C2.64442 20.5196 2.53906 20.2652 2.53906 20V6C2.53906 5.73478 2.64442 5.48043 2.83196 5.29289C3.01949 5.10536 3.27385 5 3.53906 5H7.53906ZM4.53906 15V19H20.5391V15H4.53906ZM11.5391 11V13H13.5391V11H11.5391ZM9.53906 3V5H15.5391V3H9.53906Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_510_1229">
          <rect width="24" height="24" fill="white" transform="translate(0.539062)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Ambulance = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85999 13.4575C3.75494 13.4575 2.85999 14.3525 2.85999 15.4575C2.85999 16.5625 3.75494 17.4575 4.85999 17.4575C5.95999 17.4575 6.85999 16.5625 6.85999 15.4575C6.85999 14.3525 5.95994 13.4575 4.85999 13.4575ZM4.85999 16.4575C4.30494 16.4575 3.85999 16.0075 3.85999 15.4575C3.85999 14.9075 4.30494 14.4575 4.85999 14.4575C5.40994 14.4575 5.85999 14.9075 5.85999 15.4575C5.85999 16.0075 5.40989 16.4575 4.85999 16.4575Z"
        fill="white"
      />
      <path
        d="M19.14 13.4575C18.035 13.4575 17.14 14.3525 17.14 15.4575C17.14 16.5625 18.035 17.4575 19.14 17.4575C20.2451 17.4575 21.14 16.5625 21.14 15.4575C21.14 14.3525 20.2451 13.4575 19.14 13.4575ZM19.14 16.4575C18.59 16.4575 18.14 16.0075 18.14 15.4575C18.14 14.9075 18.59 14.4575 19.14 14.4575C19.695 14.4575 20.14 14.9075 20.14 15.4575C20.14 16.0075 19.695 16.4575 19.14 16.4575Z"
        fill="white"
      />
      <path
        d="M23.5949 13.3625V9.23248C23.5949 8.97248 23.525 8.72248 23.3899 8.49748L21.135 4.69248L20.4149 3.47243C20.1549 3.03248 19.6799 2.76248 19.1699 2.76248H18.4699V1.77748C18.4699 1.09243 17.9099 0.54248 17.2349 0.54248C16.5499 0.54248 16 1.09243 16 1.77748V2.76248H1.85C1.04995 2.76248 0.405 3.41248 0.405 4.20748V13.3624C0.17495 13.4125 0 13.6025 0 13.8425V14.9575C0 15.2375 0.225 15.4575 0.5 15.4575H1.86C1.86 13.8026 3.20495 12.4575 4.86 12.4575C6.50995 12.4575 7.86 13.8026 7.86 15.4575H16.1399C16.1399 13.8026 17.485 12.4575 19.1399 12.4575C20.7949 12.4575 22.1399 13.8026 22.1399 15.4575H23.5C23.775 15.4575 24 15.2375 24 14.9575V13.8425C24 13.6025 23.8249 13.4125 23.5949 13.3625ZM9.1308 8.48768C9.34165 8.61063 9.41195 8.87858 9.2934 9.08943L9.05615 9.49798C8.93315 9.70883 8.66515 9.78353 8.45425 9.66053L7.60645 9.17293V10.1525C7.60645 10.3941 7.4088 10.5918 7.16715 10.5918H6.6927C6.4511 10.5918 6.2534 10.3941 6.2534 10.1525V9.17293L5.4056 9.66053C5.19475 9.78353 4.9268 9.70883 4.80375 9.49798L4.56655 9.08943C4.44795 8.87858 4.5182 8.61063 4.72905 8.48768L5.5769 8.00003L4.72905 7.50803C4.5182 7.38948 4.44795 7.12143 4.56655 6.91058L4.80375 6.49768C4.92675 6.28683 5.1947 6.21653 5.4056 6.33948L6.2534 6.82713V5.84753C6.2534 5.60588 6.4511 5.40823 6.6927 5.40823H7.16715C7.4088 5.40823 7.60645 5.60588 7.60645 5.84753V6.82713L8.45425 6.33948C8.66515 6.21653 8.9331 6.28683 9.05615 6.49768L9.2934 6.91058C9.41195 7.12143 9.34165 7.38943 9.1308 7.50803L8.283 8.00003L9.1308 8.48768ZM22.1 10.3975H13.655V4.26253H19.14L22.1 9.26253V10.3975Z"
        fill="white"
      />
    </svg>
  );
};

const Calender = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11H22V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V11ZM17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V9H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3Z"
        fill="white"
      />
    </svg>
  );
};

const Equipment = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1C16.552 1 17 1.448 17 2V5H21C21.552 5 22 5.448 22 6V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V6C2 5.448 2.448 5 3 5H7V2C7 1.448 7.448 1 8 1H16ZM13 9H11V12H8V14H10.999L11 17H13L12.999 14H16V12H13V9ZM15 3H9V5H15V3Z"
        fill="white"
      />
    </svg>
  );
};

const Medic = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM11 8H9V10H11V12H13V10H15V8H13V6H11V8ZM14 20H16V14H8V20H10V16H14V20Z"
        fill="white"
      />
    </svg>
  );
};
const boxKit = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 24 24" fill="none">
      <path d="M7.53906 5V2C7.53906 1.73478 7.64442 1.48043 7.83196 1.29289C8.01949 1.10536 8.27385 1 8.53906 1H16.5391C16.8043 1 17.0586 1.10536 17.2462 1.29289C17.4337 1.48043 17.5391 1.73478 17.5391 2V5H21.5391C21.8043 5 22.0586 5.10536 22.2462 5.29289C22.4337 5.48043 22.5391 5.73478 22.5391 6V20C22.5391 20.2652 22.4337 20.5196 22.2462 20.7071C22.0586 20.8946 21.8043 21 21.5391 21H3.53906C3.27385 21 3.01949 20.8946 2.83196 20.7071C2.64442 20.5196 2.53906 20.2652 2.53906 20V6C2.53906 5.73478 2.64442 5.48043 2.83196 5.29289C3.01949 5.10536 3.27385 5 3.53906 5H7.53906ZM4.53906 15V19H20.5391V15H4.53906ZM11.5391 11V13H13.5391V11H11.5391ZM9.53906 3V5H15.5391V3H9.53906Z" fill={fill} />

    </svg>
  );
};
const analysis = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 24 24" fill="none">
      <path d="M16.5388 2V4H15.5388V7.243C15.5388 8.401 15.7898 9.544 16.2748 10.595L20.5568 19.871C20.9038 20.624 20.5748 21.515 19.8228 21.861C19.6258 21.953 19.4118 22 19.1948 22H5.88281C5.05481 22 4.38281 21.328 4.38281 20.5C4.38281 20.283 4.42981 20.068 4.52081 19.871L8.80281 10.595C9.28781 9.545 9.53881 8.401 9.53881 7.243V4H8.53881V2H16.5388ZM13.5388 4H11.5388V8H13.5388V4Z" fill={fill} />

    </svg>
  );
};

const insurance = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 24 24" fill="none">
      <path d="M18.9034 10.98C20.4654 12.541 20.4654 15.074 18.9034 16.636L13.2464 22.293C12.8564 22.683 12.2224 22.683 11.8324 22.293L6.17541 16.636C4.61341 15.074 4.61341 12.541 6.17541 10.979C7.73741 9.417 10.2704 9.417 11.8324 10.979L12.5384 11.686L13.2464 10.979C14.8084 9.417 17.3414 9.417 18.9034 10.979V10.98ZM12.5394 1C14.7494 1 16.5394 2.79 16.5394 5C16.5394 7.21 14.7494 9 12.5394 9C10.3294 9 8.53941 7.21 8.53941 5C8.53941 2.79 10.3294 1 12.5394 1Z" fill={fill} />

    </svg>
  );
};
const diagnosis = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 24 24" fill="none">
      <path d="M16.5388 2V4H15.5388V7.243C15.5388 8.401 15.7898 9.544 16.2748 10.595L20.5568 19.871C20.9038 20.624 20.5748 21.515 19.8228 21.861C19.6258 21.953 19.4118 22 19.1948 22H5.88281C5.05481 22 4.38281 21.328 4.38281 20.5C4.38281 20.283 4.42981 20.068 4.52081 19.871L8.80281 10.595C9.28781 9.545 9.53881 8.401 9.53881 7.243V4H8.53881V2H16.5388ZM13.5388 4H11.5388V8H13.5388V4Z" fill={fill} />

    </svg>
  );
};
const drug = ({ width, height, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 24 24" fill="none">
      <path d="M20.3172 4.22209C22.6602 6.56509 22.6602 10.3641 20.3172 12.7071L18.1952 14.8271L13.2462 19.7781C10.9032 22.1211 7.10416 22.1211 4.76116 19.7781C2.41816 17.4351 2.41816 13.6361 4.76116 11.2931L11.8312 4.22209C14.1752 1.87909 17.9742 1.87909 20.3172 4.22209ZM15.3672 14.8281L9.71116 9.17209L6.17516 12.7071C4.61316 14.2691 4.61316 16.8021 6.17516 18.3641C7.73716 19.9261 10.2702 19.9261 11.8322 18.3641L15.3672 14.8281Z" fill={fill} />

    </svg>
  );
};
const Tele = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V0H7V2ZM7 4V9H17V4H7Z"
        fill="white"
      />
    </svg>
  );
};


const Video = ({ width, height, fill }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9.2L22.213 5.55C22.288 5.49746 22.3759 5.4665 22.4672 5.4605C22.5586 5.4545 22.6498 5.4737 22.731 5.51599C22.8122 5.55829 22.8802 5.62206 22.9276 5.70035C22.9751 5.77865 23.0001 5.86846 23 5.96V18.04C23.0001 18.1315 22.9751 18.2214 22.9276 18.2996C22.8802 18.3779 22.8122 18.4417 22.731 18.484C22.6498 18.5263 22.5586 18.5455 22.4672 18.5395C22.3759 18.5335 22.288 18.5025 22.213 18.45L17 14.8V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H2C1.73478 20 1.48043 19.8946 1.29289 19.7071C1.10536 19.5196 1 19.2652 1 19V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5V9.2ZM5 8V10H7V8H5Z"
        fill="white"
      />
    </svg>
  );
};

const LogoFade = ({ width, height, style, className }) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <path
          d="M0 10.7914C0 4.83146 4.83146 0 10.7914 0H29.2086C35.1685 0 40 4.83146 40 10.7914V29.2086C40 35.1685 35.1685 40 29.2086 40H10.7914C4.83146 40 0 35.1685 0 29.2086V10.7914Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_f)">
          <circle cx="29.3816" cy="32.4338" r="22.3475" fill="#DB4A2A" />
        </g>
        <g filter="url(#filter1_f)">
          <circle cx="24.6223" cy="10.0376" r="21.4732" fill="#2254D3" />
        </g>
        <g filter="url(#filter2_f)">
          <circle cx="1.55071" cy="21.4518" r="19.9456" fill="#FF4F4F" />
        </g>
      </g>
      <path
        d="M27.9065 13.0523C30.0763 15.2279 30.1511 18.6926 28.1328 20.9526L19.9985 29.0984L11.8662 20.9526C9.84794 18.6926 9.92372 15.2221 12.0925 13.0523C13.7789 11.367 16.2518 10.9449 18.3314 11.789L15.2513 14.8691C14.8767 15.2437 14.8766 15.851 15.2511 16.2257C15.6257 16.6006 16.2334 16.6007 16.6081 16.226L19.9995 12.8346L19.987 12.8212L20.0005 12.8336C22.2537 10.8106 25.7357 10.8777 27.9065 13.0523Z"
        fill="white"
      />
      <defs>
        <filter id="filter0_f_1206_18907" x="-16.623" y="-5.1875" width="253.606" height="253.605" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur_1206_18907" />
        </filter>
        <filter id="filter1_f_1206_18907" x="-31.1934" y="-85.8906" width="247.049" height="247.047" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur_1206_18907" />
        </filter>
        <filter id="filter2_f_1206_18907" x="-111.984" y="-37.3359" width="235.592" height="235.594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur_1206_18907" />
        </filter>
      </defs>
    </svg>
  );
};

const LogoTwo = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="102"
        height="102"
      >
        <path
          d="M0 27.518C0 12.3202 12.3202 0 27.518 0H74.482C89.6798 0 102 12.3202 102 27.518V74.482C102 89.6798 89.6798 102 74.482 102H27.518C12.3202 102 0 89.6798 0 74.482V27.518Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_f)">
          <circle cx="74.9241" cy="82.7058" r="56.9861" fill="#DB4A2A" />
        </g>
        <g filter="url(#filter1_f)">
          <circle cx="62.7854" cy="25.5967" r="54.7566" fill="#2254D3" />
        </g>
        <g filter="url(#filter2_f)">
          <circle cx="3.95359" cy="54.7021" r="50.8613" fill="#FF4F4F" />
        </g>
      </g>
      <path
        d="M71.1622 33.2833C76.6951 38.831 76.8859 47.6661 71.7394 53.429L50.997 74.2008L30.2594 53.429C25.1129 47.6661 25.3061 38.8163 30.8367 33.2833C35.1368 28.9856 41.4427 27.9094 46.7457 30.0619L38.8915 37.9161C37.9363 38.8713 37.936 40.4199 38.8909 41.3755C39.8462 42.3315 41.3957 42.3317 42.3514 41.3761L50.9994 32.7281L50.9676 32.6938L51.0019 32.7256C56.7476 27.5669 65.6268 27.7381 71.1622 33.2833Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f"
          x="-25.062"
          y="-17.2803"
          width="199.972"
          height="199.972"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter1_f"
          x="-34.9712"
          y="-72.1599"
          width="195.513"
          height="195.513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter2_f"
          x="-89.9077"
          y="-39.1592"
          width="187.723"
          height="187.723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="21.5" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
};
const Circles = ({ width, height, fill, className }) => {
  return (
    <svg
      width="73"
      height="72"
      viewBox="0 0 73 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <circle cx="46.1931" cy="27" r="26.5" fill="#9B51E0" />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <circle cx="26.5" cy="37" r="26.5" fill="#2254D3" />
      </g>
    </svg>
  );
};

const RoundedSquares = ({ width, height, fill, className }) => {
  return (
    <svg
      width="83"
      height="71"
      viewBox="0 0 83 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <rect
          x="30"
          y="0.988647"
          width="53"
          height="53"
          rx="10"
          fill="#9B51E0"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <rect y="17.0114" width="53" height="53" rx="10" fill="#2254D3" />
      </g>
    </svg>
  );
};
const Squares = ({ width, height, fill, className }) => {
  return (
    <svg
      width="83"
      height="71"
      viewBox="0 0 83 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <rect x="30" y="0.988647" width="53" height="53" fill="#9B51E0" />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <rect y="17.0114" width="53" height="53" fill="#2254D3" />
      </g>
    </svg>
  );
};

const LogoTextWhite = ({ width, height, fill, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.166992 2.99071V0.587463H9.64313V2.99071H6.25309V13.1466H3.55705V2.99071H0.166992Z"
          fill="white"
        />
        <path
          d="M9.44824 8.86528C9.44824 7.99896 9.64696 7.22752 10.0444 6.55108C10.4478 5.86864 10.9816 5.34944 11.646 4.9934C12.3163 4.63144 13.0519 4.45044 13.8527 4.45044C14.6535 4.45044 15.389 4.63144 16.0593 4.9934C16.7296 5.34944 17.2664 5.86864 17.6698 6.55108C18.0791 7.22752 18.2838 7.99896 18.2838 8.86528C18.2838 9.73164 18.0791 10.506 17.6698 11.1884C17.2664 11.8708 16.7296 12.393 16.0593 12.755C15.389 13.111 14.6535 13.289 13.8527 13.289C13.0519 13.289 12.3163 13.111 11.646 12.755C10.9816 12.393 10.4478 11.8738 10.0444 11.1973C9.64696 10.5149 9.44824 9.7376 9.44824 8.86528ZM11.9396 8.86528C11.9396 9.44088 12.1235 9.90968 12.4913 10.2716C12.865 10.6336 13.3188 10.8146 13.8527 10.8146C14.3984 10.8146 14.8552 10.6366 15.2229 10.2805C15.5966 9.91856 15.7835 9.44684 15.7835 8.86528C15.7835 8.30156 15.5966 7.83872 15.2229 7.47676C14.8492 7.1148 14.3925 6.9338 13.8527 6.9338C13.3188 6.9338 12.865 7.1148 12.4913 7.47676C12.1235 7.83872 11.9396 8.30156 11.9396 8.86528Z"
          fill="white"
        />
        <path
          d="M19.2617 6.64007V4.63735H20.8099V1.37073H23.328V4.63735H25.1165V6.64007H23.328V13.1466H20.8099V6.64007H19.2617Z"
          fill="white"
        />
        <path
          d="M26.0332 8.88308C26.0332 8.22444 26.1548 7.61324 26.398 7.04952C26.6472 6.47984 26.9764 6.01108 27.3857 5.64316C27.795 5.26932 28.2577 4.97856 28.7737 4.77088C29.2898 4.55728 29.8207 4.45044 30.3664 4.45044C30.5978 4.45044 30.844 4.47716 31.105 4.53056C31.3719 4.57804 31.6774 4.69376 32.0214 4.87768C32.3714 5.05572 32.6472 5.29012 32.8489 5.58088V4.63736H35.3581V13.1466H32.8489V12.1764C32.6057 12.5443 32.232 12.8262 31.7278 13.022C31.2295 13.2119 30.7758 13.3068 30.3664 13.3068C29.6309 13.3068 28.9369 13.1318 28.2844 12.7817C27.6318 12.4316 27.092 11.9153 26.665 11.2329C26.2438 10.5446 26.0332 9.76132 26.0332 8.88308ZM29.2987 7.43224C28.8716 7.81204 28.6581 8.29564 28.6581 8.88308C28.6581 9.47056 28.8716 9.95416 29.2987 10.334C29.7317 10.7137 30.227 10.9036 30.7846 10.9036C31.3363 10.9036 31.8168 10.7196 32.2261 10.3518C32.6413 9.97792 32.8489 9.48836 32.8489 8.88308C32.8489 8.27192 32.6413 7.78236 32.2261 7.41444C31.8168 7.0406 31.3363 6.85368 30.7846 6.85368C30.227 6.85368 29.7317 7.04656 29.2987 7.43224Z"
          fill="white"
        />
        <path d="M37.6982 13.1466V0H40.234V13.1466H37.6982Z" fill="white" />
        <path
          d="M9.13591 21.0945V26.6361H3.06776V21.0945H1.37305V33.6543H3.06776V28.1856H9.13591V33.6543H10.8488V21.0945H9.13591Z"
          fill="white"
        />
        <path
          d="M14.8623 28.7324C15.0628 27.3288 16.1197 26.4538 17.5593 26.4538C18.9442 26.4538 19.9465 27.3288 20.1469 28.7324H14.8623ZM21.787 29.3705C21.787 28.0398 21.3861 26.9825 20.5843 26.1804C19.8007 25.3601 18.7802 24.959 17.5593 24.959C16.3202 24.959 15.2815 25.3601 14.4432 26.1804C13.6232 26.9825 13.2041 28.0398 13.2041 29.3705C13.2041 30.7012 13.6232 31.7767 14.4432 32.597C15.2815 33.4173 16.3202 33.8366 17.5593 33.8366C19.254 33.8366 20.7665 33.0345 21.4772 31.5762L20.7847 31.321C20.4567 31.1934 20.2198 31.084 20.0558 31.0293C19.6003 31.8496 18.6527 32.3418 17.614 32.3418C16.1562 32.3418 15.0628 31.4304 14.8623 30.0267H21.7687C21.787 29.8991 21.787 29.6804 21.787 29.3705Z"
          fill="white"
        />
        <path
          d="M32.2774 33.6543V25.1413H30.6374V26.4174C30.1089 25.4877 28.888 24.959 27.6488 24.959C26.4462 24.959 25.4257 25.3601 24.5874 26.1804C23.7492 27.0007 23.3301 28.058 23.3301 29.3887C23.3301 30.7012 23.7492 31.7767 24.5874 32.597C25.4257 33.4173 26.4462 33.8366 27.6488 33.8366C28.888 33.8366 30.0725 33.2897 30.6374 32.3418V33.6543H32.2774ZM30.6192 29.3705C30.6192 30.2455 30.3458 30.9382 29.7991 31.4486C29.2524 31.959 28.5782 32.2142 27.7946 32.2142C27.011 32.2142 26.355 31.9408 25.8266 31.4121C25.2981 30.8835 25.0248 30.1908 25.0248 29.3705C25.0248 28.5502 25.2799 27.8757 25.8084 27.3653C26.3368 26.8366 27.011 26.5814 27.7946 26.5814C28.5418 26.5814 29.1978 26.8366 29.7627 27.3288C30.3276 27.821 30.6192 28.5137 30.6192 29.3705Z"
          fill="white"
        />
        <path
          d="M35.0527 33.6543H36.711V20.5112H35.0527V33.6543Z"
          fill="white"
        />
        <path
          d="M41.7797 21.933H40.1213V25.1413H38.5908V26.545H40.1213V33.6543H41.7797V26.545H43.5837V25.1413H41.7797V21.933Z"
          fill="white"
        />
        <path
          d="M47.1213 29.0606C47.1213 28.222 47.3765 27.6022 47.9049 27.2012C48.4333 26.782 49.0345 26.5814 49.7089 26.5814C50.8933 26.5814 51.7133 27.4564 51.7133 28.8418V33.6543H53.3717V28.7324C53.3897 26.3444 52.0597 24.959 49.9277 24.959C49.3993 24.959 48.8525 25.0866 48.2877 25.3418C47.7409 25.5788 47.3581 25.9434 47.1213 26.4174V20.5112H45.4629V33.6543H47.1213V29.0606Z"
          fill="white"
        />
        <path
          d="M65.2574 30.5918C64.3462 31.6673 63.1798 32.196 61.777 32.196C60.4646 32.196 59.353 31.7402 58.4602 30.8106C57.5674 29.8809 57.1118 28.7324 57.1118 27.4017C57.1118 26.071 57.5674 24.9226 58.4602 23.9929C59.353 23.0632 60.4646 22.5893 61.777 22.5893C63.1254 22.5893 64.2734 23.0997 65.221 24.1387L66.4966 23.0814C65.3486 21.7507 63.6718 20.9486 61.777 20.9486C60.0094 20.9486 58.4966 21.5867 57.2574 22.8445C56.0366 24.1023 55.417 25.6153 55.417 27.4017C55.417 29.1882 56.0366 30.7012 57.2574 31.959C58.4966 33.2168 60.0094 33.8366 61.777 33.8366C63.6902 33.8366 65.385 33.0345 66.533 31.6673L65.2574 30.5918Z"
          fill="white"
        />
        <path
          d="M76.7304 33.6543V25.1413H75.0904V26.4174C74.562 25.4877 73.3412 24.959 72.102 24.959C70.8992 24.959 69.8788 25.3601 69.0404 26.1804C68.2024 27.0007 67.7832 28.058 67.7832 29.3887C67.7832 30.7012 68.2024 31.7767 69.0404 32.597C69.8788 33.4173 70.8992 33.8366 72.102 33.8366C73.3412 33.8366 74.5256 33.2897 75.0904 32.3418V33.6543H76.7304ZM75.072 29.3705C75.072 30.2455 74.7988 30.9382 74.252 31.4486C73.7056 31.959 73.0312 32.2142 72.2476 32.2142C71.464 32.2142 70.808 31.9408 70.2796 31.4121C69.7512 30.8835 69.478 30.1908 69.478 29.3705C69.478 28.5502 69.7328 27.8757 70.2616 27.3653C70.79 26.8366 71.464 26.5814 72.2476 26.5814C72.9948 26.5814 73.6508 26.8366 74.2156 27.3288C74.7808 27.821 75.072 28.5137 75.072 29.3705Z"
          fill="white"
        />
        <path
          d="M81.1639 30.5554C81.1639 28.1491 81.9475 26.545 83.4967 26.545C83.9339 26.545 84.3531 26.6361 84.7539 26.8366L85.0091 25.2872C84.6991 25.0684 84.2071 24.959 83.5331 24.959C82.3667 24.959 81.5831 25.6518 81.1639 27.0554V25.1413H79.5059V33.6543H81.1639V30.5554Z"
          fill="white"
        />
        <path
          d="M87.5754 28.7324C87.7758 27.3288 88.8326 26.4538 90.2722 26.4538C91.657 26.4538 92.6594 27.3288 92.8598 28.7324H87.5754ZM94.4998 29.3705C94.4998 28.0398 94.099 26.9825 93.297 26.1804C92.5138 25.3601 91.493 24.959 90.2722 24.959C89.033 24.959 87.9942 25.3601 87.1562 26.1804C86.3362 26.9825 85.917 28.0398 85.917 29.3705C85.917 30.7012 86.3362 31.7767 87.1562 32.597C87.9942 33.4173 89.033 33.8366 90.2722 33.8366C91.967 33.8366 93.4794 33.0345 94.1902 31.5762L93.4978 31.321C93.1698 31.1934 92.9326 31.084 92.7686 31.0293C92.313 31.8496 91.3654 32.3418 90.327 32.3418C88.869 32.3418 87.7758 31.4304 87.5754 30.0267H94.4818C94.4998 29.8991 94.4998 29.6804 94.4998 29.3705Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="94.8" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowRight = ({ width, fill, className }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 12H19"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Notes = ({ width, height, fill, className }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="100"
        height="100"
      >
        <circle cx="50" cy="50" r="50" fill="#2EB8E4" />
        <circle cx="50" cy="50" r="50" fill="#2EB8E4" />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_d)">
          <path
            d="M67.563 -31.0116C68.9672 -31.0116 70.1068 -29.9238 70.1068 -28.5834V-19.4607L47.2147 2.39304L47.1994 12.6837L58.0006 12.6983L70.1068 1.14252V15.1241C70.1068 16.4644 68.9672 17.5522 67.563 17.5522H26.8612C25.457 17.5522 24.3174 16.4644 24.3174 15.1241V-28.5834C24.3174 -29.9238 25.457 -31.0116 26.8612 -31.0116H67.563ZM72.086 -14.4805L75.683 -11.047L55.8968 7.83948L52.2947 7.83462L52.2998 4.40601L72.086 -14.4805ZM47.2121 -6.72968H34.4928V-1.87329H47.2121V-6.72968ZM54.8437 -16.4424H34.4928V-11.5861H54.8437V-16.4424Z"
            fill="white"
          />
          <rect
            x="24.3359"
            y="89.8217"
            width="45.7357"
            height="44.5325"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="14.3174"
          y="-37.0116"
          width="71.3656"
          height="185.366"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

const Pulse = ({ width, height, fill, className }) => {
  return (
    <svg
      width="82"
      height="76"
      viewBox="0 0 82 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M54.6213 5.99939C63.8176 5.99939 71.2703 13.5671 71.2703 24.1619C71.2703 45.3515 48.5671 57.4599 40.9994 62.0005C35.0118 58.4073 19.5555 50.0829 13.356 36.2703H27.5773L30.4046 31.5601L39.4858 46.6955L45.7398 36.2703H56.1348V30.2161H42.3131L39.4858 34.9262L30.4046 19.7908L24.1506 30.2161H11.3642C10.9525 28.2878 10.7285 26.2688 10.7285 24.1619C10.7285 13.5671 18.2962 5.99939 27.3775 5.99939C33.0079 5.99939 37.9723 9.02648 40.9994 12.0536C44.0265 9.02648 48.9909 5.99939 54.6213 5.99939Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.728516"
          y="-0.000610352"
          width="80.5418"
          height="76.0011"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

const Flag = ({ width, height, fill, className }) => {
  return (
    <svg
      width="58"
      height="65"
      viewBox="0 0 58 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M10.25 6.25H29.7958C30.1826 6.25021 30.5617 6.35809 30.8907 6.56157C31.2196 6.76504 31.4854 7.05608 31.6583 7.40208L33.1667 10.4167H45.6667C46.2192 10.4167 46.7491 10.6362 47.1398 11.0269C47.5305 11.4176 47.75 11.9475 47.75 12.5V35.4167C47.75 35.9692 47.5305 36.4991 47.1398 36.8898C46.7491 37.2805 46.2192 37.5 45.6667 37.5H32.3708C31.984 37.4998 31.6049 37.3919 31.276 37.1884C30.947 36.985 30.6812 36.6939 30.5083 36.3479L29 33.3333H14.4167V75.8333H10.25V6.25Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.25"
          y="0.25"
          width="57.5"
          height="89.5833"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const Bars = ({ width, height, fill, className }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="100"
        height="100"
      >
        <circle cx="50" cy="50" r="50" fill="#071232" />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter0_d)">
          <path
            d="M43.7497 132.5H33.333V55.8333H43.7497V132.5ZM56.2497 132.5H45.833V47.5H56.2497V132.5ZM68.7497 132.5H58.333V39.1666H68.7497V132.5Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="23.333"
          y="33.1666"
          width="55.4167"
          height="113.333"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

const Health = ({ width, height, fill, className }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 40.7556C44.1425 40.7556 47.5 44.1131 47.5 48.2556C47.5 52.3981 44.1425 55.7556 40 55.7556C35.8575 55.7556 32.5 52.3981 32.5 48.2556C32.5 44.1131 35.8575 40.7556 40 40.7556ZM15 30.7556C20.525 30.7556 25 35.2306 25 40.7556C25 46.2806 20.525 50.7556 15 50.7556C9.475 50.7556 5 46.2806 5 40.7556C5 35.2306 9.475 30.7556 15 30.7556ZM36.25 5.75562C43.845 5.75562 50 11.9106 50 19.5056C50 27.1006 43.845 33.2556 36.25 33.2556C28.655 33.2556 22.5 27.1006 22.5 19.5056C22.5 11.9106 28.655 5.75562 36.25 5.75562Z"
        fill="#FF4F4F"
      />
    </svg>
  );
};
const Heart = ({ width, height, fill, className }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.4079 27.8681C46.9179 24.3856 52.6079 24.3856 56.1179 27.8681C59.6279 31.3531 59.6279 37.0031 56.1179 40.4856L42.5004 54.0056L28.8829 40.4856C25.3729 37.0031 25.3729 31.3531 28.8829 27.8681C32.3929 24.3856 38.0829 24.3856 41.5929 27.8681L42.5004 28.7681L43.4079 27.8681ZM48.1079 12.1481C50.4579 14.5056 51.8654 17.4431 52.3304 20.4931C48.9979 19.8731 45.4829 20.4681 42.5004 22.2756C37.1254 19.0181 30.0154 19.7006 25.3604 24.3206C20.0329 29.6081 19.8854 38.1081 24.9154 43.5731L25.3604 44.0356L31.4129 50.0456L27.5004 53.9681L6.30036 32.7381C1.04286 26.8481 1.24036 17.8031 6.89286 12.1481C12.5554 6.48807 21.6129 6.29806 27.5029 11.5781C33.3754 6.30556 42.4504 6.48057 48.1079 12.1481Z"
        fill="orange"
      />
    </svg>
  );
};
const Heart2 = ({ width, height, fill, className }) => {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.4079 27.8681C46.9179 24.3856 52.6079 24.3856 56.1179 27.8681C59.6279 31.3531 59.6279 37.0031 56.1179 40.4856L42.5004 54.0056L28.8829 40.4856C25.3729 37.0031 25.3729 31.3531 28.8829 27.8681C32.3929 24.3856 38.0829 24.3856 41.5929 27.8681L42.5004 28.7681L43.4079 27.8681ZM48.1079 12.1481C50.4579 14.5056 51.8654 17.4431 52.3304 20.4931C48.9979 19.8731 45.4829 20.4681 42.5004 22.2756C37.1254 19.0181 30.0154 19.7006 25.3604 24.3206C20.0329 29.6081 19.8854 38.1081 24.9154 43.5731L25.3604 44.0356L31.4129 50.0456L27.5004 53.9681L6.30036 32.7381C1.04286 26.8481 1.24036 17.8031 6.89286 12.1481C12.5554 6.48807 21.6129 6.29806 27.5029 11.5781C33.3754 6.30556 42.4504 6.48057 48.1079 12.1481Z"
        fill="white"
      />
    </svg>
  );
};
const HeadPhone = ({ width, height, fill, className }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 20.2557C53.8261 20.2557 55.0979 20.7825 56.0355 21.7201C56.9732 22.6578 57.5 23.9296 57.5 25.2557V35.2557C57.5 36.5818 56.9732 37.8535 56.0355 38.7912C55.0979 39.7289 53.8261 40.2557 52.5 40.2557H49.845C49.2355 45.0889 46.8832 49.5336 43.2294 52.7557C39.5756 55.9777 34.8715 57.7556 30 57.7557V52.7557C33.9782 52.7557 37.7936 51.1753 40.6066 48.3623C43.4197 45.5492 45 41.7339 45 37.7557V22.7557C45 18.7774 43.4197 14.9621 40.6066 12.1491C37.7936 9.33602 33.9782 7.75567 30 7.75567C26.0218 7.75567 22.2064 9.33602 19.3934 12.1491C16.5804 14.9621 15 18.7774 15 22.7557V40.2557H7.5C6.17392 40.2557 4.90215 39.7289 3.96447 38.7912C3.02678 37.8535 2.5 36.5818 2.5 35.2557V25.2557C2.5 23.9296 3.02678 22.6578 3.96447 21.7201C4.90215 20.7825 6.17392 20.2557 7.5 20.2557H10.155C10.7651 15.4229 13.1177 10.9789 16.7714 7.75736C20.4252 4.53587 25.1289 2.75836 30 2.75836C34.8711 2.75836 39.5748 4.53587 43.2286 7.75736C46.8823 10.9789 49.2349 15.4229 49.845 20.2557H52.5ZM19.4 39.7182L22.05 35.4782C24.4327 36.9708 27.1884 37.7602 30 37.7557C32.8116 37.7602 35.5673 36.9708 37.95 35.4782L40.6 39.7182C37.4231 41.7085 33.7489 42.7614 30 42.7557C26.2511 42.7614 22.5769 41.7085 19.4 39.7182Z"
        fill="#2254D3"
      />
    </svg>
  );
};

const CloseModal = ({ width, height, fill, className, onClick }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <circle
        cx="27.0598"
        cy="27.0598"
        r="27.0598"
        fill="white"
        fill-opacity="0.12"
      />
      <path
        d="M27.0593 25.6457L32.0093 20.6957L33.4233 22.1097L28.4733 27.0597L33.4233 32.0097L32.0093 33.4237L27.0593 28.4737L22.1093 33.4237L20.6953 32.0097L25.6453 27.0597L20.6953 22.1097L22.1093 20.6957L27.0593 25.6457Z"
        fill="white"
      />
    </svg>
  );
};

const Sample = ({ width, height, fill, className }) => {
  return <div />;
};
const Tick = ({ width, height, fill, className }) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.364 1L4.36399 7L1.63672 4.27273"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export {
  Chinese,
  Ambulance,
  Calender,
  Equipment,
  Medic,
  Tele,
  Video,
  LogoFade,
  LogoTwo,
  Circles,
  RoundedSquares,
  Squares,
  LogoTextWhite,
  ArrowRight,
  Notes,
  Pulse,
  boxKit,
  diagnosis,
  insurance,
  analysis,
  Flag,
  Bars,
  Health,
  Heart,
  Heart2,
  HeadPhone,
  CloseModal,
  Tick,
  drug,
};
