import React, { useRef, useEffect, useState } from "react";
import Layout from "../../components/layout";
import Banner from "./banner";
import Services from "./services";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
import SolutionBanner from "../../components/solutionBanner/SolutionBanner";
gsap.registerPlugin(ScrollToPlugin);

const Solutions = (props) => {
  let allRef = useRef(null);
  let independentRef = useRef(null);
  let hosRef = useRef(null);
  let pharmRef = useRef(null);
  let hmoRef = useRef(null);
  let diagRef = useRef(null);
  let ngoRef = useRef(null);
  let path = props.match.params.type;

  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };
  useEffect(() => {
    if (path === "all" || !path) {
      scrollTo(allRef);
    } else if (path === "independent-consultants") {
      scrollTo(independentRef);
    } else if (path === "hospitals") {
      scrollTo(hosRef);
    } else if (path === "pharmacies") {
      scrollTo(pharmRef);
    } else if (path === "diagnostic-centers") {
      scrollTo(diagRef);
    } else if (path === "hmos") {
      scrollTo(hmoRef);
    } else if (path === "ngos") {
      scrollTo(ngoRef);
    }
  }, [path]);

  return (
    <Layout
      headerClass="bg-dark"
      solutions="text-white no-fade"
      dropClass="btn-outline-white"
      isDark
    >
      <SolutionBanner/>
      <Services />
    </Layout>
  );
};
export default Solutions;
