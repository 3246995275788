import React from "react";
import "./styles.scss";
const Card = ({
  className,
  src,
  imgClass,
  alt,
  title,
  body,
  withIcons,
  circleClass,
  icon,
  imageStyle
}) => {
  return (
    <div>
      <div className="card_container mb-3 w-100 d-justify-center d-align-start">
      <img alt={alt} src={icon} className={`${imgClass}`} style={{ marginTop: imageStyle }} />
      </div>
      <div
        className={`app-card flex-column d-justify-start d-align-center rounded-16 p-3 bg-white mb-2 glitch-in ${className}`}
      >

        {!withIcons ? (

          <div className="card_img_container mb-1 w-100 d-justify-center d-align-start">

            <img alt={alt} src={src} className={`${imgClass}`} />
          </div>
        ) : (
          <div className="card_icon_container mb-1 w-100 d-justify-center d-align-start">
            <div className={`card_icon_circle ${circleClass}`}>
              {" "}
              <img alt={alt} src={src} className={`${imgClass}`} />
            </div>
          </div>
        )}

        <h3 className="text-dark mb-1 text-left aileron text-20 bold">{title}</h3>

        <p className="text-light text-center aileron">{body}</p>
      </div>
    </div>
  );
};
export default Card;
