import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout";
import Banner from "./banner";
import Intro from "./intro";
import Services from "./services";
import Cards from "./cards";
import Body from "./body";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
import BlogStore from "../../stores/blogStore";
import testKitsStore from "../../stores/testKitsStore";
gsap.registerPlugin(ScrollToPlugin);

const Home = () => {
  let bodyRef = useRef(null);
  // const [imgs, setImgs] = useState([
  //   "https://res.cloudinary.com/olamilekan1/image/upload/v1620665998/family.png",
  //   "https://res.cloudinary.com/olamilekan1/image/upload/v1621170803/doctor.jpg",
  // ]);
  const [photos, setPhotos] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setShow(true);
    const imgs = [
      "https://res.cloudinary.com/olamilekan1/image/upload/v1620665998/family.png",
      "https://res.cloudinary.com/olamilekan1/image/upload/v1621170803/doctor.jpg",
    ];

    //  cacheImgs(imgs);
  }, []);

  useEffect(() => {
    (async () => {
      if (!BlogStore.dataCached) {
        await Promise.all([BlogStore.getBlogs(), testKitsStore.getTestKits()]);
      }
    })();
  }, []);

  const cacheImgs = async (srcArr) => {
    setShow(true);
    let imgsCopy = [];
    const promises = await srcArr.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
        setPhotos([...photos, img]);
        // setPhotos(img);
        imgsCopy.push(...photos, img);
      });
    });

    await Promise.all(promises);
    console.log(imgsCopy);
    setPhotos(imgsCopy);
    setShow(false);
  };
  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };
  return (
    <Layout
      headerClass="bg-white"
      home="text-primary"
      dropClass="btn-outline-primary"
    >
      {/* {show ? (
        <LoadingScreen two className="loading_screen_fixed d-center" />
      ) : null} */}

      <>
        <Banner
          onScrollClick={() => scrollTo(bodyRef)}
          src="https://res.cloudinary.com/olamilekan1/image/upload/q_80/f_webp/v1620665998/family.webp"
          onLoad={() => {
            console.log("Loaded image");
            setShow(false);
          }}
          onError={() => {
            console.log("Failed image");
            setShow(false);
          }}
        />
        <div ref={(el) => (bodyRef = el)} className="pt-5 pt-lg-0 mt-4">
          <Intro />
        </div>
        <Services />
        <Cards />
        <Body docImg={require('../../assets/images/helping_doctors_prescribe.bmp')}
        docImg3={require('../../assets/images/doctors_team.bmp')}
        docImg4={require('../../assets/images/prescripiton_image.bmp')}
        />
      </>
    </Layout>
  );
};
export default Home;
