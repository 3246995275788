import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Drop = ({ show, data, onClick }) => {
  return show ? (
    <div className="nav-drop rounded-xl slide-down py-2 bg-white">
      {data.map((item, i) => {
        return (
          <Link to={item.link} key={i + item.name} onClick={onClick}>
            <div className="pointer text-dark no-wrap px-2">{item.name}</div>
          </Link>
        );
      })}
    </div>
  ) : null;
};

const NavSide = ({ data, onClick, active }) => {
  return (
    <div className="nav-side rounded-xl slide-down py-3 px-0 bg-white w-100 scroll-x ">
      <h3 className="mb-2 text-dark inter text-x px-2 text-b">All Tests</h3>
      {data &&
        data.map((item, i) => {
          return (
            <Link
              to={"#"}
              key={i + "item" + item?.sys?.id}
              onClick={() => onClick(item)}
            >
              <div
                className={`pointer no-wrap px-2 py-1  inter ${
                  active === item?.fields?.name
                    ? "text-dark bg-light-blue text-b"
                    : "text-light  bg-white text-n normal"
                }`}
              >
                {item?.fields?.name}
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default Drop;
export { NavSide };
