import React, { useRef, useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imag_logo from "../../assets/images/web_logo.png"

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
  let servicesRef = useRef([]);
  servicesRef.current = [];
  useEffect(() => {
    servicesRef.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, opacity: 0 },
        {
          duration: 0.5,
          autoAlpha: 1,
          opacity: 1,
          ease: "none",
          translateY: -30,
          scrollTrigger: {
            trigger: el,
            //     start: "top center+=100",
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, []);
  const revealLists = (elem) => {
    if (elem && !servicesRef.current.includes(elem)) {
      servicesRef.current.push(elem);
    }
  };

  return (
    <div className="ml-n1 website_content mt-5  footer_section" 
    style={{
      height: "23rem"
    }}
    >
      <div className="flex-wrap ml-1 d-justify-between  flex-row d-align-center   "
      
      
      >
       
<div  style={{
  marginRight: "-4rem"
}}>

<h3 className="text-dark mb-1  inter text-x text-b" style={{  fontSize: "22px", }}>
              Welcome to  <br />
              Total HealthCare!
            </h3>
            <img src={imag_logo} style={{ width: "4rem", height: "4rem", marginBottom: "1rem" }} />
</div>
<div>

</div>
<div className="text-light aileron text-n "  style={{width: "80%", marginTop: "1rem" }}>
 <p className="text-light aileron text-n"  style={{ fontSize: "1rem", marginLeft: "3rem", marginRight: "0.8rem" }}> We understand the complexities involved in managing healthcare services and finding the right healthcare options, and our goal is to ensure seamless access to quality healthcare.</p>
 <p className="text-light text-left aileron text-n"  style={{ fontSize: "1rem", marginLeft: "3rem", marginRight: "0.5rem" }}>Acknowledging the intricacies involved in providing telemedicine services across different regions and platforms, our primary objective is to ensure smooth and uninterrupted interactions in such scenarios. Leveraging our state-of-the-art technology, we have simplified the process of connecting healthcare providers and patients remotely, while prioritizing the safety and security of their data at every step.</p>
 <p className="text-light text-left aileron text-n"  style={{ fontSize: "1rem",  marginLeft: "3rem" , marginRight: "0.5rem" }}>At Total HealthCare, we are committed to empowering healthcare organizations of all sizes. We offer powerful tools that enable them to embrace new opportunities for growth while upholding the highest standards of security and convenience. By partnering with us, you can confidently access a comprehensive range of telemedicine solutions tailored to meet the specific needs of your organization, regardless of your geographical location.</p>
</div>
      </div>
    </div>
  )

};
export default Services;
