import(/* webpackPreload: true */ "./website/assets/fonts/Metropolis-Medium.woff2");

import(/* webpackPreload: true */ "./website/assets/fonts/SofiaProMedium.woff2");
import(/* webpackPreload: true */ "./website/assets/fonts/SofiaProBold.woff2");
import(/* webpackPreload: true */ "./website/assets/fonts/SofiaProSemiBold.woff2");
import(
  /* webpackPreload: true */ "./website/assets/fonts/aileron-regular.woff2"
);
import(/* webpackPreload: true */ "./website/assets/fonts/Product-Sans.woff2");

import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  // Website routes
  Home,
  Solution,
  AllSolutions,
  Plans,
  TestKit,
  AllTestKit,
  ContactUs,
  TermsOfUse,
  PrivacyPolicy,
  NotFound,
} from "./pages";

import Solutions from "./website/screens/solutions";
import Blog from "./website/screens/blog";
import BlogDetail from "./website/screens/blogDetail";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  HOME,
  SOLUTIONS,
  ALL_SOLUTIONS,
  HOSPITAL,
  INDEPENDENT,
  TEST_KIST,
  TEST_KIST_ALL,
  PLANS,
  CONTACT_US,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  BLOG,
  BLOG_DETAIL,
  HOSPITAL_SOLUTIONS,
  PHARMACY_SOLUTION,
  DIAGNOSTICS_SOLUTION,
  HEALTH_INSURANCE_SOLUTION,
  COMPANY_AND_NGO_SOLUTION,
  FAQ,
  INDEOENDENT_PRICING,
  DIAGNOSTIC_CENTER_PRICING,
  PHARMACIES_PRICING,
  INSURANCE_PROVIDER_PRICING,
  ABOUT_US,
  INDEPENDENT_SOLUTION,
  CORE_VALUES,
  VISSION_MISSION,
} from "./router/router";
import { transitions, positions, Provider as AlertProvider } from "react-alert";


import Faq from "./website/screens/faq";
import AboutUS from "./website/screens/aboutUs";
import IndependentSolutions from "./website/screens/independent";
import HealthInsuranceSolution from "./website/screens/hmos";
import CompaniesAndNgoSolution from "./website/screens/ngos";
import HospitalSolutions from "./website/screens/hospitals";
import DiagnosticSolutions from "./website/screens/diagnostics";
import PharmacySolutions from "./website/screens/pharmacies";

import InsurancePricing from "./website/screens/pricing/insurancePricing";

import IndependentPricing from "./website/screens/pricing/independentPricing";
import DiagnosticCenterPricing from "./website/screens/pricing/diagnosticsPricing";
import PharmacyPricing from "./website/screens/pricing/pharmacyPricing";
import CoreValues from "./website/screens/coreValues";
import VissionAndMission from "./website/screens/vission & mission";






// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};
const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={style}
    className={`app_alert ${options.type === "info"
      ? "app_alert_info"
      : options.type === "success"
        ? "app_alert_success"
        : options.type === "error"
          ? "app_alert_error"
          : ""
      }`}
  >
    <span>
      {options.type === "info" && "⚠ "}
      {options.type === "success" && "💚 "}
      {options.type === "error" && "💔 "}
    </span>
    {message}
    <button onClick={close}>X</button>
  </div>
);
ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        {/* Website */}

        <Route path={HOME} component={Home} />
        <Route path={SOLUTIONS} component={Solution} />
        <Route path={ALL_SOLUTIONS} component={Solutions} />
        <Route path={TEST_KIST} component={TestKit} />
        <Route path={TEST_KIST_ALL} component={AllTestKit} />
        <Route path={PLANS} component={Plans} />
        <Route path={CONTACT_US} component={ContactUs} />
        <Route path={TERMS_OF_USE} component={TermsOfUse} />
        <Route path={PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path={BLOG} component={Blog} />
        <Route path={BLOG_DETAIL} component={BlogDetail} />
        <Route path={HOSPITAL_SOLUTIONS} component={HospitalSolutions} />
        <Route path={INDEPENDENT_SOLUTION} component={IndependentSolutions} />
        <Route path={PHARMACY_SOLUTION} component={PharmacySolutions} />
        <Route path={DIAGNOSTICS_SOLUTION} component={DiagnosticSolutions} />
        <Route path={HEALTH_INSURANCE_SOLUTION} component={HealthInsuranceSolution} />
        <Route path={COMPANY_AND_NGO_SOLUTION} component={CompaniesAndNgoSolution} />
     x   <Route path={INDEOENDENT_PRICING} component={IndependentPricing} />
        <Route path={DIAGNOSTIC_CENTER_PRICING} component={DiagnosticCenterPricing} />
        <Route path={INSURANCE_PROVIDER_PRICING} component={InsurancePricing} />
        <Route path={PHARMACIES_PRICING} component={PharmacyPricing} />
        <Route path={FAQ} component={Faq} />
        <Route path={ABOUT_US} component={AboutUS} />
        <Route path={CORE_VALUES} component={CoreValues} />
        <Route path={VISSION_MISSION} component={VissionAndMission} />
        {/* <Route path="*" component={NotFound} /> */}
      </Switch>
    </Router>{" "}
  </AlertProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
