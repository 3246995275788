import React from "react";

const NavToggleBtn = ({ close, click, className }) => (
  <button className={`hamburger_btn ${close} ${className}`} onClick={click}>
    <div className="hamburger" />
    <div className="hamburger" />
    <div className="hamburger" />
  </button>
);
export default NavToggleBtn;
