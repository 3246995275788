import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout";
import Banner from "./banner";
import Blogs from "./blogs";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
import BlogStore from "../../stores/blogStore";
gsap.registerPlugin(ScrollToPlugin);

const Home = (props) => {
  let bodyRef = useRef(null);
  const [items, setItems] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!BlogStore.dataCached) {
        let data = await BlogStore.getBlogs();
        setItems(data);
      }
    })();

    setItems(BlogStore.blogItems);
  }, []);

  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };
  return (
    <Layout
      headerClass="bg-white"
      blog="text-primary"
      dropClass="btn-outline-primary"
    >
      <Banner
        onScrollClick={() => scrollTo(bodyRef)}
        item={items && items[0]}
      />
      <div className=" pt-lg-0">
        <Blogs props={props} />
      </div>
    </Layout>
  );
};
export default Home;
