import React, { useState } from "react";
import DBbackground_down from "../../assets/images/DBbackground_down.png";
import DBMenuItem from "../DBMenuItem";
import {
  Logo,
  LogoText,
} from "../DBIcons";
import { LogoFade, LogoTwo } from "../icons";
import { Link } from "react-router-dom";
import {
  HOME,
  // LOGIN,
  // PRACTICE_SELECT,
  HMO_SIGNUP,
  HOSPITAL_SIGNUP,
  PHARMACY_SIGNUP,
  LABORATORY_SIGNUP,
  TEST_KIST,
  PLANS,
  BLOG,
  ALL_SOLUTIONS,
} from "../../../router/router";
const SideNav = ({ show }) => {
  //const [show, setShow] = useState(false);
  return (
    <div
      className={`sidenav ${show ? "db_side_nav_open" : "db_side_nav_close"}`}
    >
      <Link to={HOME} className="d-center mt-4 mb-5">
        <LogoFade width="2em" height="2em" style={{ marginRight: "-5px" }} />

        <LogoText width="7em" height="1.5em" style={{ marginRight: "-20px" }} />
      </Link>

      <DBMenuItem title="Home" target={HOME} />
      <DBMenuItem title="Solutions" target={ALL_SOLUTIONS} />
      <DBMenuItem title="Test Kits" target={TEST_KIST} />
      <DBMenuItem title="Plans" target={PLANS} />
      <DBMenuItem title="Blog" target={BLOG} />
      {/* <DBMenuItem title="Register" target={PRACTICE_SELECT} /> */}
      {/* <DBMenuItem title="Login" target={LOGIN} /> */}
      <img src={DBbackground_down} className="DBbackground_down" />
    </div>
  );
};

export default SideNav;
