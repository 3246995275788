import React from "react";
import { DetailShapesPoint, Point } from "../../components/points";
import HealthPlanCard from "../../components/cards/healthPlanCard";
import HealthFam from "../../assets/images/health_fam.png";
import BussPlan from "../../assets/images/business_card.png";
import IntPlan from "../../assets/images/international_plan.png";
import "./styles.scss";


const HealthPlans = () => {
  const plans = [
    {
      src: HealthFam,
      class: "card_register",
    },
    {
      src: BussPlan,
      class: "card_register",
      imgClass: {
        height: "20px"
      },
    },
    {
      src: IntPlan,
      class: "card_register",

    },
  ];
  return (
    <div className="website_content  mb-5 section ">
      <div className="w-100 ml-2 mb-3 ">
        <Point
          title="Health Insurance Plans"
          style={{ fontSize: "28px", }}
          body="The Total HealthCare innovative digital healthcare platform provides HMOs the tools to manage remote consultancy for their existing clients and offer insurance plans to new subscribers. "
          className="col-7"
        />
      </div>
      <div className="flex-row ml-2 d-align-center flex-wrap d-justify-between " >
        {plans.map((item, i) => {
          return (
            <div className="flex-row ml-1" key={i + "card" + i} >
              <HealthPlanCard imgClass={item.imgClass} src={item.src} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HealthPlans;