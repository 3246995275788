import React from "react";
import Layout from "../../components/layout";
import Banner from "./banner";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
gsap.registerPlugin(ScrollToPlugin);

const Faq = () => {

    return (
        <Layout
            headerClass="bg-white"
            home="text-primary"
            dropClass="btn-outline-primary"
        >
            <Banner />
            {/* <div className=" mb-0  banner  website_content padding_nulify" >
                <p
                    className="text-light mb-4 mt-5mb-lg-2 aileron w-lg-50"

                >
                    Q.1 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>

                <p
                    className="text-light mb-4 mb-lg-2 aileron w-lg-50"

                >A.1  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
                <p
                    className="text-light mb-4 mb-lg-2 aileron w-lg-50"

                >
                    Q.2 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
                <p
                    className="text-light mb-4 mb-lg-2 aileron w-lg-50"

                >
                    A.2  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                </p>
            </div> */}
        </Layout>
    );
};
export default Faq;
