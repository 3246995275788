


//Website Routes
export const HOME = "/home";
export const SOLUTIONS = "/solutions/:type";
export const ALL_SOLUTIONS = "/solutions";
export const INDEPENDENT = "/solutions/independent-consultants";
export const HOSPITAL = "/solutions/hospitals";
export const INDEPENDENT_SOLUTION = "/independent-services"
export const HOSPITAL_SOLUTIONS = "/hospital";
export const PHARMACY_SOLUTION = "/pharmacies"
export const PHARMACIES = "/solutions/pharmacies";
export const HMOS = "/solutions/hmos";
export const DIAGNOSTICS = "/solutions/diagnostic-centers";
export const FAQ = "/faq";
export const DIAGNOSTICS_SOLUTION = "/diagnostic";
export const NGOS = "/solutions/ngos";
export const TERMS_OF_USEAGE = "/termsofuse";
export const ABOUT_US = "/about-us"
export const CORE_VALUES = "/core-values"
export const VISSION_MISSION = "/vission-mission"
export const HEALTH_INSURANCE_SOLUTION = "/HealthInsurance";
export const COMPANY_AND_NGO_SOLUTION = "/company-and-ngo";
export const TEST_KIST = "/test-kits";
export const TEST_KIST_ALL = "/test-kits-all";
export const PLANS = "/plans";
export const CONTACT_US = "/contact";
export const TERMS_OF_USE = "/terms-of-use";
export const PRIVACY_POLICY = "/privacy-policy";
export const BLOG = "/blog";
export const SUPPORT = "/support"
export const BLOG_DETAIL = "/blogs/:type";
export const INDEOENDENT_PRICING = "/independent-pricing";
export const DIAGNOSTIC_CENTER_PRICING = "/diagnostic-center-pricing";
export const PHARMACIES_PRICING = "/pharmacies-pricing";
export const INSURANCE_PROVIDER_PRICING = "/insurance-provider-pricing";

