import React from "react";
import "./styles.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
const BlogItem = ({
  src,
  alt,
  time,
  title,
  body,
  author,
  className,
  noImg,

  authorImageAlt,
  authorImage,
  authorName,
  authorRole,
  bodyClass = "ellipses-text-4",
}) => {
  return (
    <div
      className={`flex-column d-justify-start d-align-start blog-item scale-in-out ${className}`}
    >
      {!noImg && (
        <LazyLoadImage
          alt={alt}
          src={src}
          effect="blur"
          className="blog-item-image"
        />
      )}
      {!noImg && (
        <span className="text-n text-ligt aileron-light my-2">
          {moment(time).format("LL")}
        </span>
      )}
      <h3 className="inter text-b text-xl mb-2 blog-item-title">{title}</h3>
      <p className={`mb-2 text-light aileron text-n ${bodyClass}`}>{body}</p>
      <div className="d-justify-start d-align-start blog-item-author">
        <LazyLoadImage alt={authorImageAlt} src={authorImage} effect="blur" />
        <div className="flex-column d-justify-start d-align-start ml-1">
          <label className="inter text-20 text-dark mb-0">{authorName}</label>
          <label className="text-light aileron text-14">{authorRole}</label>
        </div>
      </div>
    </div>
  );
};
export default BlogItem;
