import React from "react";
import loadable from "@loadable/component";
import { LoadingScreen } from "../src/website/components/Loadingpage";
import Layout from "./website/components/layout";

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}

/**
 * loadable component is used to lazy load (code split) each component
 * All pages are loaded from this file
 */

const AppLoader = () => {
  return (
    <Layout>
      <LoadingScreen two className="loading_screen_fixed d-center" />
    </Layout>
  );
};

const DbLoader = () => {
  return <LoadingScreen two className="loading_screen_fixed d-center" />;
};

//Website Pages
const Home = loadable(
  () => componentLoader(() => import("./website/screens/home"), 6),
  {
    fallback: <AppLoader />,
  }
);
const Solution = loadable(
  () => componentLoader(() => import("./website/screens/solutions"), 6),
  {
    fallback: <AppLoader />,
  }
);

const AllSolutions = loadable(
  () => componentLoader(() => import("./website/screens/solutions"), 6),
  {
    fallback: <AppLoader />,
  }
);
const TestKit = loadable(() => import("./website/screens/testKit"), {
  fallback: <AppLoader />,
});
const AllTestKit = loadable(() => import("./website/screens/allTests"), {
  fallback: <AppLoader />,
});
const Plans = loadable(() => import("./website/screens/pricing"), {
  fallback: <AppLoader />,
});
const Blog = loadable(() => import("./website/screens/blog"), {
  fallback: <AppLoader />,
});
const BlogDetail = loadable(() => import("./website/screens/blogDetail"), {
  fallback: <AppLoader />,
});
const ContactUs = loadable(() => import("./website/screens/contactUs"), {
  fallback: <AppLoader />,
});
const TermsOfUse = loadable(() => import("./website/screens/termOfUse"), {
  fallback: <AppLoader />,
});
const PrivacyPolicy = loadable(
  () => import("./website/screens/privacyPolicy"),
  {
    fallback: <AppLoader />,
  }
);

// const NotFound = loadable(() => import("./Components/pages/NotFound"), {
//   fallback: <AppLoader />,
// });
export {
  // Website pages
  Home,
  Solution,
  AllSolutions,
  TestKit,
  AllTestKit,
  Plans,
  ContactUs,
  TermsOfUse,
  PrivacyPolicy,
  Blog,
  BlogDetail,
  // NotFound,
};
