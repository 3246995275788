import React from "react";
import img_bg from "../../assets/images/gdpr.png";
import hippa_mm from "../../assets/images/hppa.png";
import web_logo from "../../assets/images/web_logo.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { LogoTextWhite } from "../icons";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import {
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from "../../../router/router";

const Footer = ({ isDark }) => {
  return (
    <footer className="footer bg-dark">
      <div className="mt-5 text-center main_section" style={{ paddingTop: "28px",}}>
        <h1 className=" text-white inter  " style={{ fontSize: "28px",}}>
          The future of HealthCare is here
        </h1>
      </div>


      <div className="footer-content" style={{ display: "flex", 
      flexDirection: "row", 
      // justifyContent:"space-between"

    }}>
        <div className="mt-4 logo-section">

          <div className="flex-row d-justify-start mb-5">
            <img src={web_logo} style={{ width: "2.5rem", height: "2.5rem", marginRight: "0.5rem", 
            marginLeft: "1rem", marginBottom: "0rem", marginTop: "0.25rem" }} />
            {/* <LogoFade width="4em" height="4em" className="mr-1" /> */}
            <div className="flex-row d-justify-start mt-n2">
              <LogoTextWhite width="90" height="80" />
            </div>
          </div>
          <div className="follow-us ml-3 mt-n5" >
            <span className="ml-1 text-white text-bold">Follow us:</span>
            <div className="ml-1 social-icons">
              <a className="flex-row">
                <FaFacebookF width="30px" height="30px" fill="#fff" className="mr-4" />
                <FaInstagram width="30px" height="30px" fill="#fff" className="mr-4" />
                <FaTwitter width="30px" height="30px" fill="#fff" className="mr-4" />
                <FaLinkedinIn width="18px" height="18px" fill="#fff" />
              </a>
            </div>
          </div>
        </div>

        <div className="menu-section" style={{ display: "flex", flexDirection: "row", width: "60%", 
       marginTop: "1rem",  justifyContent:"space-around", marginLeft: "8rem" }}>
          <div className="menu-column" >
            <ul>
              <li className="text-white">Solutions</li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Independent doctors
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Hospitals
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Pharmacies
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Diagnostic centres
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Insurance providers
              </li>
            </ul>
          </div>
          <div className="menu-column">
            <ul>
              <li className="text-white">Company</li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                About Us
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Work with us
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Blog
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Terms of Service
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Privacy Policy
              </li>
            </ul>
          </div>
          <div className="menu-column">
            <ul>
              <li className="text-white">Resource</li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Help
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Support centre
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Cookies
              </li>
            </ul>
          </div>
          <div className="menu-column">
            <ul>
              <li className="text-white">Contact us:</li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                info@totalhealthcare360.com
              </li>
              {/* <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                +1 302 319 9896
              </li> */}
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                #2311, 1007 N Orange St.
              </li>
              <li className="text-white text-thin aileron-light mb-1 aileron text-n">
                Wilmington, Delaware 19801, <br />
                United States

              </li>
            </ul>
          </div>
        </div>
        {/* <div className="mt-2 mb-4 flex-column " style={{ marginTop: "-2.5rem" }}>
          <div >
            <img src={img_bg} style={{ width: "10rem", height: "6rem", marginBottom: "1.3rem", marginRight: '1rem' }} />
          </div>
          <div>
            <img src={hippa_mm} style={{ width: "6rem", height: "6rem", marginLeft: "2rem", marginRight: "2rem", }} />
          </div>
        </div> */}

      </div>


      <div className="bg-primary d-justify-between d-align-center py-2 px-md-5 px-3 w-100">
        <Link
          className="text-white mr-md-5 mr-2 mb-0 aileron"
        >
          © 2024. Total HealthCare. All rights reserved
        </Link>
        <div>
          <Link
            className="text-white mr-md-5 mr-2 mb-0 aileron"
            to={TERMS_OF_USE}
          >
            Terms of Service
          </Link>
          <Link className="text-white mr-3 mb-0 aileron" to={PRIVACY_POLICY}>
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;