import React, { useState, useRef, useEffect } from "react";
import SubCard from "../../components/cards/subCrad";
import { Tick, ArrowRight } from "../../components/icons/index";
import { Button2 } from "../../components/buttons/index";
import SectionPlan from "../../components/planLayout";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
import BlogStore from "../../stores/blogStore";
import testKitsStore from "../../stores/testKitsStore";
import Section from "../../components/planHeader";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollToPlugin);

const SubPlans = ({
  className,
  type,
  price,
  time,
  desc,
  data,
  onSubClick,
  active,
  onClick,
}) => {
  const plans = [
    {
      type: "BRONZE",
      price: "₦0",
      time: "Month",
      desc: "Get started with your basic practice tools",
      data: [
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
      ],
      class: "card_register",
    },
    {
      type: "SILVER",
      price: "₦9,000",
      time: "Month",
      desc: "Get the best out of Total Healthcare platform by having more storage",
      data: [
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
      ],
      class: "card_register",
    },
    {
      type: "PLATINUM",
      price: "₦18,000",
      time: "Month",
      desc: "Practise more effectively with our customized solutions",
      data: [
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
        { name: "Lorem ipsum dolor sit amet" },
      ],
      class: "card_register",
    },
  ];

  const [activePlan, setActivePlan] = useState(plans[1]);
  let bodyRef = useRef(null);
  // const [imgs, setImgs] = useState([
  //   "https://res.cloudinary.com/olamilekan1/image/upload/v1620665998/family.png",
  //   "https://res.cloudinary.com/olamilekan1/image/upload/v1621170803/doctor.jpg",
  // ]);
  const [photos, setPhotos] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setShow(true);
    const imgs = [
      "https://res.cloudinary.com/olamilekan1/image/upload/v1620665998/family.png",
      "https://res.cloudinary.com/olamilekan1/image/upload/v1621170803/doctor.jpg",
    ];

    //  cacheImgs(imgs);
  }, []);

  useEffect(() => {
    (async () => {
      if (!BlogStore.dataCached) {
        await Promise.all([BlogStore.getBlogs(), testKitsStore.getTestKits()]);
      }
    })();
  }, []);

  const cacheImgs = async (srcArr) => {
    setShow(true);
    let imgsCopy = [];
    const promises = await srcArr.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
        setPhotos([...photos, img]);
        // setPhotos(img);
        imgsCopy.push(...photos, img);
      });
    });

    await Promise.all(promises);
    console.log(imgsCopy);
    setPhotos(imgsCopy);
    setShow(false);
  };
  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };
  return (
    <div className="d-justify-between d-align-start ml-0  flex-wrap mb-0 website_content ">
      <div className="w-100">
        <h1 className="text-dark mb-3 inter d-align-start text-left ml-2 inter text-x bold plan_section " style={{ fontSize: "26px", marginTop: "-5rem" }}>Subscription Plans</h1>
        {/* <SectionPlan
          headerClass="bg-white"
          home="text-primary"
          dropClass="btn-outline-primary"
        // className=" plan_section"
        >

        </SectionPlan> */}
        <Section />

        <h1 className="text-dark mb-0 inter text-left ml-2  col-12 inter text-x bold" style={{ fontSize: "21px" }}>Basic Plan(Free)</h1>
        <div

          className={`app-sub-card mb-4 flex-row d-justify-between d-align-center py-2 mb-2  `}
          style={{ marginRight: "2rem", marginLeft: "1rem", borderRadius: "1rem", width: "96%" }}
          onClick={onClick}
        >

          <p className="ml-3 text-n inter" style={{ fontSize: "15px", color: "gray" }}>Our free plan provides xxxx, xxxxxx, xxxxxx. Get started </p>

          <Button2
            onClick={onSubClick}
            text={
              <div className="text-primary text-b" >
                Subscribe
                <ArrowRight
                  width="16px"
                  height="16px"
                  fill="#2254D3"
                  className="ml-1"
                />
              </div>
            }
            className="mb-2 btn-outline-primary  bg-white mr-3 py-3 mt-2 slide-right-container app-sub-card-submit "

          />

        </div>
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5">
        {plans.map((item, i) => {
          return (
            <div className="col-12 col-md-6 col-lg-4" key={i + item.price}>
              <SubCard
                className={`shake${item.type === "SILVER" ? " blue" : ""}`}
                type={item.type}
                price={item.price}
                time={item.time}
                desc={item.desc}
                data={item.data}
                onClick={() => setActivePlan(item)}
                active={item.type === activePlan.type ? true : false}
              />
            </div>
          );
        })}
      </div>
      <div className="w-100">

        <h1 className="text-dark mb-0 inter text-left ml-2  col-12 inter text-x bold" style={{ fontSize: "21px" }}>Platinium Subscription</h1>

        <div

          className={`app-sub-card mb-4 flex-row d-justify-between d-align-center py-2 mb-2  `}
          style={{ marginRight: "2rem", marginLeft: "1rem", borderRadius: "1rem", width: "96%" }}
          onClick={onClick}
        >

          <p className="ml-3 text-n inter" style={{ fontSize: "15px", color: "gray" }}>Contact us for platinium subscription </p>

          <Button2
            onClick={onSubClick}
            text={
              <div className="text-primary text-b" >
                Contact Us
                <ArrowRight
                  width="16px"
                  height="16px"
                  fill="#2254D3"
                  className="ml-1"
                />
              </div>
            }
            className="mb-2 btn-outline-primary  bg-white mr-3 py-3 mt-2 slide-right-container app-sub-card-submit "

          />

        </div>
      </div>
    </div>
  );
};

export default SubPlans;