import React from "react";
import {
  Ambulance,
  Calender,
  Health,
  Heart,
  Heart2,
  Medic,
  Tele,
  Video,
  boxKit,
  diagnosis,
  drug,
  insurance,
} from "../../components/icons";
import { IconPoint } from "../../components/points";
import { Link } from "react-router-dom";
const Services = () => {
  const services = [
    {
      name: "Independent Doctors",
      details:
        "The SaaS and mobile application system is a modern digital platform designed specifically for independent healthcare providers. It offers a range of operational tools that enable doctors and consultants to provide medical services remotely. With this system, they can easily manage their patients' records, schedule appointments, and even rent facilities or equipment when they are consulting outside of their usual location. This platform simplifies the process for independent consultants to deliver quality healthcare services, no matter where they are located.",
      icon: boxKit,
      link: "/independent-services",
    },
    {
      name: "Hospitals",
      details:
        "Hospitals benefit from a comprehensive operational toolkit offered by the digital platform and mobile application. This enables them to efficiently manage and expand their remote consultancy services, catering to both existing and new patients. Moreover, the platform integrates a sophisticated medical records system and facilitates emergency ambulance services and equipment rental for visiting consultants.",
      icon: Medic,
      link: "/hospital",
    },
    {
      name: "Pharmacies",
      details: `Pharmacies are granted access to a wide range of patients and hospitals through the SaaS and mobile application system. This platform facilitates the seamless procurement of medicines for those in need. By connecting pharmacies with patients and hospitals, the system enhances accessibility and ensures a smoother process for medication acquisition.`,
      icon: drug,
      link: "/pharmacies",
    },
    {
      name: "Health Insurance Providers",
      details:
        "Health insurance providers are equipped with the necessary tools through the digital platform and mobile app to effectively handle pre-authorization requests from their current clients and extend insurance plans to new subscribers. This technology solution streamlines the process, allowing health insurance providers to efficiently manage their operations and cater to the needs of both existing and prospective policyholders.",
      icon: insurance,
      link: "/HealthInsurance",
    },
    {
      name: "Diagnostic Centres",
      details:
        "The SaaS and mobile application system opens up opportunities for diagnostic centers and laboratories to connect with patients requiring test services and referrals from hospitals. By leveraging this platform, diagnostic centers can easily reach out to patients in need of tests, ensuring efficient and convenient access to necessary medical services. Additionally, the system facilitates seamless communication and collaboration between diagnostic centers, hospitals, and patients, enhancing the overall healthcare experience.",
      icon: diagnosis,
      link: "/diagnostic",
    },
    {
      name: "NGOs/ Companies",
      details: "NGOs and Companies can utilize the platform to offer essential medical service support to their staff, members and the general public. By leveraging the digital infrastructure, They can efficiently connect individuals in need with healthcare resources and services. This includes facilitating access to medical consultations, providing information on health-related topics, and coordinating assistance for those requiring medical support. The platform empowers NGOs to extend their reach and enhance their impact in delivering crucial medical services to their members and the wider community.",
      icon: Heart2,
      link: "/company-and-ngo",
    },
  ];
  return (
    <div className="website_content mt-5 pt-3">
      <div className="flex-wrap d-justify-between d-align-start px-5 padding_nulify">
        <h1 className="mb-5 mt-2 text-dark col-12 inter h2">
          Built for all health care providers
        </h1>
        {services.map((item, i) => {
          let Icon = item.icon;
          let name = item.name;
          return (
            <Link
              key={i + item.name}
              className="col-12 col-md-6 col-xxl-4"
              to={item.link}
            >
              <IconPoint
                iconClass="bg-purple"
                title={name}
                body={item.details}
                icon={<Icon fill="#fff" width="18px" height="18px" />}
                arrow
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default Services;
