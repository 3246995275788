import React from "react";
import { NavLink } from "react-router-dom";

class DBMenuItem extends React.Component {
  render() {
    return (
      <div className={`db_menu_item ${this.props.logoutclass}`}>
        <NavLink
          activeClassName="navbar_link_active"
          className="navbar_link"
          to={this.props.target}
        >
          <button onClick={this.props.logoutClick}>
            <nav
              className={this.props.className}
              style={{
                marginLeft: "1.7em",
                marginRight: "auto",
                width: "5em",
              }}
            >
              {this.props.icon}
              <h6 style={{ marginBottom: "0",
              fontSize:"8.0px" }}>{this.props.title}</h6>
            </nav>
          </button>
        </NavLink>
      </div>
    );
  }
}

export default DBMenuItem;
