



import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout";
import Intro from "./intro";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
import BlogStore from "../../stores/blogStore";
import testKitsStore from "../../stores/testKitsStore";

gsap.registerPlugin(ScrollToPlugin);

const VissionAndMission= () => {
  let bodyRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setShow(true);
    const imgs = [
      "https://res.cloudinary.com/olamilekan1/image/upload/v1620665998/family.png",
      "https://res.cloudinary.com/olamilekan1/image/upload/v1621170803/doctor.jpg",
    ];

    // cacheImgs(imgs);
  }, []);

  useEffect(() => {
    (async () => {
      if (!BlogStore.dataCached) {
        await Promise.all([BlogStore.getBlogs(), testKitsStore.getTestKits()]);
      }
    })();
  }, []);

  const cacheImgs = async (srcArr) => {
    setShow(true);
    let imgsCopy = [];
    const promises = await srcArr.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
        setPhotos([...photos, img]);
        // setPhotos(img);
        imgsCopy.push(...photos, img);
      });
    });

    await Promise.all(promises);
    console.log(imgsCopy);
    setPhotos(imgsCopy);
    setShow(false);
  };

  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };

  return (
    <Layout headerClass="bg-white" home="text-primary" dropClass="btn-outline-primary">
      <>
        <Intro />
      </>
    </Layout>
  );
};

export default VissionAndMission;
