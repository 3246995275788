import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
//Animate text
export const textIntro = (elem) => {
    gsap.from(elem, {
        xPercent: -10,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        translateX: -200,
        ease: "back",
    });
};
export const textIntroTwo = (elem) => {
    gsap.from(elem, {
        xPercent: -20,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        translateY: 100,
        ease: "back",
        delay: 0.1,
    });
};

export const play = (elem) => {
    gsap.from(elem, {
        xPercent: -20,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        translateY: 100,
        ease: "back",
        delay: 1,
    });
};

export const apple = (elem) => {
    gsap.from(elem, {
        xPercent: -20,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        translateY: 100,
        ease: "back",
        delay: 1.4,
    });
};

export const bannerPic = (elem) => {
    gsap.from(elem, {
        xPercent: 20,
        opacity: 0,
        stagger: 0.6,
        duration: 2,
        ease: "back",
        delay: 0.4,
    });
};

export const scrollTitle = (elem, id) => {
    gsap.from(elem, {
        // opacity: 0,
        stagger: 0.6,
        duration: 1,
        ease: "back",
        scrollTrigger: {
            id: id,
            trigger: elem,
            start: "top ",
            toggleActions: "play none none reverse",
        },
    });
};

export const scrollIntro = (elem, id) => {
    gsap.from(elem, {
        // xPercent: 20,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        ease: "back",
        scrollTrigger: {
            id: id,
            trigger: elem,
            start: "top center+=100",
            toggleActions: "play none none reverse",
            delay: 1,
        },
    });
};

export const scrollCards = (elem, id, x, d) => {
    gsap.from(elem, {
        xPercent: x,
        opacity: 0,
        stagger: 0.6,
        duration: 1,
        translateY: -30,
        ease: "back",
        delay: d,
        scrollTrigger: {
            id: id,
            trigger: elem,
            start: "top center+=200",
            toggleActions: "play none none none",
        },
    });
};

export const scrollLists = (elem) => {
    gsap.fromTo(
        elem,
        { autoAlpha: 0 },
        {
            duration: 1.5,
            autoAlpha: 1,
            ease: "none",
            translateY: -40,

            scrollTrigger: {
                trigger: elem,
                //  start: "top center+=5",
                toggleActions: "play none none none",
            },
        }
    );
};
