import { data } from "jquery";
import React from "react";
import { Tick, ArrowRight } from "../icons";
import { Button } from "../../components/buttons";
import "./styles.scss";
const SubCard = ({
  className,
  type,
  price,
  time,
  desc,
  data,
  onSubClick,
  active,
  onClick,
}) => {
  return (
    <div
      className={`app-sub-card flex-column d-justify-start d-align-center rounded-xl px-2 py-4 mb-2 w-100 glitch-in ease ${className} ${active ? "bg-primary app-sub-active" : "bg-white"
        }`}
      onClick={onClick}
    >
      <h3 className={`normal aileron mb-1 text-n`}>{type}</h3>
      <p className="mb-1 d-center">
        <span className="text-xl text-dark bold inter sub-card-price">
          {price}
        </span>
        <span className="text-light text-n inter normal sub-card-time">
          /{time}
        </span>
      </p>
      <p className="text-light text-n inter normal mb-4 app-sub-card-desc text-center aileron">
        {desc}
      </p>

      <div className="flex-column d-justify-start d-align-center mb-2">
        {data.map((item, i) => {
          return (
            <div
              className="flex-row d-justify-start d-align-start mb-3 aileron app-sub-card-items"
              key={"name" + i}
            >
              <div className="app-sub-card-circle mr-1 d-center">
                <Tick />
              </div>

              {item.name}
            </div>
          );
        })}
      </div>

      <Button
        onClick={onSubClick}
        text={
          <div className="text-primary text-b">
            Subscribe
            <ArrowRight
              width="16px"
              height="16px"
              fill="#2254D3"
              className="ml-1"
            />
          </div>
        }
        className="mb-2 btn-outline-primary bg-white py-2 mt-2 slide-right-container app-sub-card-submit"
      />
    </div>
  );
};
export default SubCard;
