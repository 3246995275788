import React from "react";
import { textIntro, textIntroTwo, play, apple, bannerPic } from "./animate";
import BlogItem from "../../components/blogItem";
import BlogStore from "../../stores/blogStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const Banner = ({ item }) => {
  return (
    <div className="d-justify-between d-align-start flex-wrap mb-0 banner_blog website_content padding_nulify pt-0 my-5">
      <div className="col-12 col-md-7 flex-column d-justify-start d-align-start my-mob-5 my-auto" style={{ paddingTop: "5rem" }}>
        <LazyLoadImage
          src={item?.fields?.blogImage?.fields?.file?.url}
          alt={item?.fields?.blogImage?.fields?.title}
          effect="blur"
          className="blog-banner-image"
        />
      </div>
      <Link
        alt="family"
        className="col-12 col-md-5 my-auto"
        to={`/blogs/${item?.sys?.id}`}
      >
        <BlogItem
          noImg
          title={item?.fields?.title}
          body={item?.fields?.body}
          authorImageAlt={item?.fields?.authorImage?.fields?.title}
          authorImage={item?.fields?.authorImage?.fields?.file?.url}
          authorName={item?.fields?.authorName}
          authorRole={item?.fields?.authorRole}
        />
      </Link>
    </div>
  );
};

export default Banner;
