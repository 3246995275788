import React, { useRef, useEffect } from "react";
import Hands from "../../assets/images/hands.png";
import { AppButton } from "../../components/buttons";
import { FaApple } from "react-icons/fa";
import { textIntro, textIntroTwo, apple, bannerPic } from "./animate";
import { Button } from "../../components/buttons";
import { ChevronDownThin } from "../../components/DBIcons";

const Banner = ({ onScrollClick }) => {
    let intro = useRef(null);
    let introTwo = useRef(null);

    let appleRef = useRef(null);
    let famRef = useRef(null);
    useEffect(() => {
        textIntro(intro);
        textIntroTwo(introTwo);

        apple(appleRef);
        bannerPic(famRef);
    }, []);
    return (
        <div className="d-justify-between d-align-start  flex-wrap mb-0 banner_home website_content padding_nulify" style={{ marginTop: "7rem" }}>
            <div className="col-12 col-md-6 flex-column d-justify-start d-align-start my-auto my-mob-5" style={{ marginTop: "15rem" }}>
                <h1
                    className="text-dark mb-2  mb-lg-0 inter text-left"
                    ref={(el) => (intro = el)}
                    style={{ fontWeight: "bold" }}
                >
                    FAQ
                </h1>
                <p
                    className="text-light mb-4 mb-lg-2 aileron w-lg-60 faq_text"
                    ref={(el) => (introTwo = el)}
                >
                    Find below some common questions asked by our <br />customers. We are more than happy to provide more <br />clarification if required
                </p>
                <div className="d-justify-start d-align-start mb-lg-0 mb-5">


                    <div ref={(el) => (appleRef = el)}>

                        <AppButton


                            type="Chat with Us"
                            className="btn-dark"
                        />
                    </div>
                </div>

                {/* <div className="w-fit mt-5 mobile">
                    <Button
                        onClick={onScrollClick}
                        text={
                            <div>
                                Scroll below
                                <ChevronDownThin
                                    width="12px"
                                    height="12px"
                                    fill="#fff"
                                    className="ml-1"
                                />
                            </div>
                        }
                        className="mb-5 btn-dark py-2 mt-5 slide-right-container"
                    />
                </div> */}
            </div>
            <img
                alt="family"
                src={Hands}
                className="col-12 col-md-6 my-auto desktop faq_image"
                ref={(el) => (famRef = el)}
            />

        </div>
    );
};

export default Banner;
