import { observable, runInAction } from "mobx";
import { contentClient } from "../../Services/contentful-service";
export class BlogStore {
  @observable blogItems = [];
  @observable dataCached = false;

  getBlogs = async () => {
    let response;
    await contentClient
      .getEntries({ content_type: "blog" })
      .then((res) => {
        runInAction(() => {
          response = res.items;
          this.blogItems = res.items;
          this.dataCached = true;
        });
      })
      .catch(console.error);
    console.log("response", response);
    return response;
  };
}
export default new BlogStore();

// {
// content_type: "blog",
// select: "3UZCm3hlDblXBOwHcnIfjf,fields.<field_name>",
// }
