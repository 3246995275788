import React from "react";
import "./styles.scss";
const TextCard = ({ className, tag, title }) => {
  return (
    <div className={`rounded-xxl text-card p-4 glitch-in ${className}`}>
      <span className="p-sm py-sm  px-2 rounded-xl bg-transparent text-white btn-trans aileron text-14">
        {tag}
      </span>
      <h2 className="text-white bold px-4 inter text-xl spaced-xl">{title}</h2>

      <div className="large-circle-outline rounded-circle" />
      <div className="large-circle-fill rounded-circle" />
    </div>
  );
};
export default TextCard;
