import React, { useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import {
    PLANS,
    INDEOENDENT_PRICING,
    PHARMACIES_PRICING,
    INSURANCE_PROVIDER_PRICING,
    DIAGNOSTIC_CENTER_PRICING,
} from "../../../router/router";

const Section = ({
    plans,
    independent,
    pharmacy,
    pricing,
    insuranceProvider,
    className,
}) => {
    const links = [
        { name: "HOSPITAL", link: PLANS, active: plans },
        { name: "DIAGNOSTIC CENTERS", link: DIAGNOSTIC_CENTER_PRICING, active: independent },
        { name: "PHARMACIES", link: PHARMACIES_PRICING, active: pharmacy },
        { name: "INDEPENDENT CONSULTANT", link: INDEOENDENT_PRICING, active: pricing },
        { name: "INSURANCE PROVIDER", link: INSURANCE_PROVIDER_PRICING, active: insuranceProvider },
    ];

    const col = [
        { name: "HOSPITAL", link: PLANS, active: plans },
        { name: "DIAGNOSTIC CENTERS", link: DIAGNOSTIC_CENTER_PRICING, active: independent },
        { name: "PHARMACIES", link: PHARMACIES_PRICING, active: pharmacy },
        { name: "INDEPENDENT CONSULTANT", link: INDEOENDENT_PRICING, active: pricing },
        { name: "INSURANCE PROVIDER", link: INSURANCE_PROVIDER_PRICING, active: insuranceProvider },
    ];
    const [activeIndex, setActiveIndex] = useState(0);
    const handleItemClick = (item) => {
        setActiveIndex(prevActiveItem => prevActiveItem === item ? null : item);
    };
    return (
        <div className={`ml-n4 w-100 mb-2 ${className}`}>
            <div className="d-justify-between d-align-center">
                <ul className="d-justify-between d-align-center w-100 mb-1 ">
                    {links.map((item, i) => {
                        return (
                            <li key={item + i} className={`mr-3`}>
                                <Link
                                    to={item.link}
                                    className={`no-wrap inter text-n ${item.active === activeIndex ? "active" : ""}`}
                                    style={{ fontSize: "14px", color: item.active ? "primary" : "inherit" }}
                                    onClick={() => handleItemClick(item.active ? null : i)}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Section;