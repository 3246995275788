


import React, { useEffect, useRef } from "react";
import { AboutUsPoint, Point } from "../../components/points";
import mission from "../../assets/images/mission.png";
import vission from "../../assets/images/vision.png";

const Intro = ({ docImg }) => {


  return (
    <div className="">


      <div style={{
        backgroundColor: "#def7d0",
        height: "30rem",
        marginBottom: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

      }} className="content">



        <div className="ml-n2 website_content  " >
          <div className="card_content">

            <div className="flex-column ml-n4 flex-wrap mt-3 goal_section">
              <div className="flex-column flex-wrap ml-n4 px-1 px-lg-5">
                <div
                  className="d-justify-between d-align-start flex-wrap mb-5 pt-4"

                >

                  <AboutUsPoint
                    title="Mission"
                    image={mission}
                    text=" To offer an inclusive online healthcare service that enables individuals and businesses to access medical assistance and support remotely, regardless of geographical boundaries. We are dedicated to establishing a world where healthcare is readily accessible, convenient, and cost-effective through digital platforms in the global community.."
                    body="  "
                    className="col-9 col-md-5 bg-white rounded-lg p-10  card_height"
                    style={{ height: "330px" }}
                  />


                  <AboutUsPoint
                    title="Vision"
                    image={vission}
                    text={"Our Vision: To create a world where healthcare services are seamless, borderless, and accessible to everyone"}
                    body=""
                    className="col-9 col-md-5 w-full lg:w-1/2 bg-white rounded-lg p-10 card_height"
                    style={{ height: "330px" }}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
