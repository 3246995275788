import React, { useEffect, useRef, useState } from "react";
import BlogItem from "../../components/blogItem";
import BlogStore from "../../stores/blogStore";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div className="website_content">
      <section className="flex-wrap d-justify-start d-align-start">
        {BlogStore.blogItems.map((item, i) => {
          if (i !== 0) {
            return (
              <Link
                className="mb-3 col-12 col-md-6 col-lg-4 col-xxl-4"
                key={i + "item" + item?.sys?.id}
                to={`/blogs/${item?.sys?.id}`}
              >
                <BlogItem
                  src={item?.fields?.blogImage?.fields?.file?.url}
                  alt={item?.fields?.blogImage?.fields?.title}
                  time={item?.fields?.date}
                  title={item?.fields?.title}
                  body={item?.fields?.body}
                  authorImageAlt={item?.fields?.authorImage?.fields?.title}
                  authorImage={item?.fields?.authorImage?.fields?.file?.url}
                  authorName={item?.fields?.authorName}
                  authorRole={item?.fields?.authorRole}
                />
              </Link>
            );
          }
        })}
      </section>
    </div>
  );
};
export default Blogs;
