import React from "react";
import "./styles.scss";
const HealthPlanCard = ({ className, src, imgClass, alt, title, body }) => {
  return (
    <div
      className={`app-health-card flex-row rounded-xl p bg-white mb-2 ${className}`}
      style={{ height: "28rem", width: "19rem" }}
    >
      <div className="app-health-card-circle-s" />
      <div className="app-health-card-circle-b" />

      <img src={src} alt={alt} />
    </div>
  );
};
export default HealthPlanCard;
