import React from "react";
import { Point } from "../../components/points";
import HosScreen from "../../assets/images/hospital_screen1.png";

const Screen = () => {
  return (
    <div className="website_content mt-5 pt-3 d-center mb-5">
      <div className="d-flex flex-column w-fit d-center">
        <img alt="hospital" src={HosScreen} className="hospital_screen mb-1" />

        <Point
          className="mb-1 w-100 w-md-60 text-center d-center"
          title="Sign up as a provider"
          body="Profile setup allows for the patients to be able to see services offered on the patient app"
        />
      </div>
    </div>
  );
};
export default Screen;
