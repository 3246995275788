import React, { useEffect, useRef } from "react";
import { scrollIntro, scrollTitle } from "./animate";
const Intro = () => {
  //  let textRef = useRef(null);
  let titleRef = useRef(null);
  useEffect(() => {
    scrollTitle(titleRef, "intro_title");
    //   scrollIntro(textRef, "intro_text");
  }, []);
  return (
    <div className=" website_content info_content " >
      <div className="flex-column d-center text-center intro mb-5 pb-5 px-lg-5 px-1">
        <h1
          className="h2 text-dark mb-2  inter  ml-n6  home_health_care_text"
          ref={(el) => (titleRef = el)}
          id="intro_title"
          style={{ marginLeft: "-7rem", marginTop: "rem" }}
        >
          Total HealthCare
        </h1>

        <p
          className="text-light   " id="intro_text"
          //   ref={(el) => (textRef = el)}
          style={{

            textAlign: "left",
            width: "91%",
            fontSize: "1rem"

          }}
        >


          Total HealthCare is an innovative digital healthcare platform that ensures timely access to quality healthcare services for everyone. The Total HealthCare SaaS web interface and mobile app system constitute a comprehensive 360-degree healthcare ecosystem for both caregivers and patients. This system enables hospitals, independent doctors, diagnostic centers, laboratories, pharmacies, health insurance providers, schools and company clinics to manage their day-to-day operations. At the same time, it offers digital services and remote consultations to their customers.
        </p>
      </div>
    </div>
  );
};
export default Intro;
