/** @format */

import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import "./styles.scss";
import SideNav from "../sidenav";
const PageLayout = ({
  children,
  headerClass,
  home,
  solutions,
  test,
  pricing,
  support,
  register,
  login,
  dropClass,
  onClick,
  isDark,
}) => {
  const [toggled, setToggled] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);
  return (
    <div
      className={`vw-max bg-white overflow-hidden ${toggled && ""}`}
      id="app-body"
    >
      <div className="">
        <div className="">
          <Header
            className={headerClass}
            home={home}
            solutions={solutions}
            test={test}
            // pricing={pricing}
            support={support}
            register={register}
            login={login}
            dropClass={dropClass}
            onClick={onClick}
            isDark={isDark}
            navClick={() => setShow((prev) => !prev)}
            navClosed={show ? "ham_crossed" : ""}
          />
          {show ? (
            <div
              className="backdrop"
              onClick={() => setShow((prev) => !prev)}
            />
          ) : null}
          <SideNav show={show} />
          <div className="website_container bg-white">
            <div className="page-min website_contents">{children}</div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
