import React from "react";
import { Logo, LogoText } from "../components/DBIcons";
import { LogoFade } from "../../website/components/icons";
export function LoadingPage() {
  return (
    <div className="loading_page">
      <Logo width="5.6em" height="5.6em" />
      <LogoText width="12em" height="4.62em" />
    </div>
  );
}
export function LoadingScreen({ two, className }) {
  return (
    <div className={`loading_screen ${className} `}>
      {!two ? (
        <LogoFade width="3em" height="3em" className="thc_logo" />
      ) : (
        <LogoFade width="3em" height="3em" className="thc_logo" />
      )}
    </div>
  );
}
