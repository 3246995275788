import React, { useState, useEffect } from "react";
import BlogItem from "../../components/blogItem";
import BlogStore from "../../stores/blogStore";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = ({ props }) => {
  let path = props.match.params.type;

  const [blogItem, setBlogItem] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      if (!BlogStore.dataCached) {
        let data = await BlogStore.getBlogs();
        setItems(data);
      }
    })();

    setItems(BlogStore.blogItems);
  }, []);

  useEffect(() => {
    getCurrentBlog();
  });

  //looping through the list of blogs to find current blog
  const getCurrentBlog = () => {
    let currentItem = items.find(
      (item, i) => item?.sys?.id.toString() === path.toString()
    );
    console.log("Current Item", currentItem);
    setBlogItem(currentItem);
    if (currentItem) {
    } else {
      //props.history.push("/*");
    }
  };
  return (
    <div className="d-justify-center d-align-start flex-wrap mb-0 padding_nulify mt-5 pt-5">
      <div alt="family" className="col-10 col-md-8 mb-5">
        <BlogItem
          noImg
          title={blogItem?.fields?.title}
          body={blogItem?.fields?.body}
          authorImageAlt={blogItem?.fields?.authorImage?.fields?.title}
          authorImage={blogItem?.fields?.authorImage?.fields?.file?.url}
          authorName={blogItem?.fields?.authorName}
          authorRole={blogItem?.fields?.authorRole}
          bodyClass="ellipses-text-2"
        />
      </div>
      <div className="col-12  flex-column d-justify-start d-align-start mb-5 padding_nulify_two blog-detail-hero-image-container">
        <div
          className="blog-detail-hero-image"
          style={{
            backgroundImage: `url(${blogItem?.fields?.blogImage?.fields?.file?.url})`,
          }}
        />
      </div>

      {/* <LazyLoadImage src={blogItem.image} alt={blogItem.alt} effect="blur" /> */}

      <p className="text-light aileron text-n  pb-5 col-10 col-md-8">
        {blogItem?.fields?.body.split("\n").map((str, i) => {
          return (
            <p
              style={{ marginBottom: str == "" ? "30px" : "" }}
              key={i + "str"}
              className="text-light aileron text-n"
            >
              {str}
            </p>
          );
        })}

        {/* <p className="text-light aileron text-n">{blogItem?.fields?.body}</p> */}
      </p>
    </div>
  );
};

export default Banner;
