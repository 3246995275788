import React, { useEffect } from "react";
import Layout from "../../components/layout/index";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import Banner from "../pricing/banner"
import SubPlans from "../pricing/subPlans";
import HealthPlans from "../pricing/healthPlans";

gsap.registerPlugin(ScrollToPlugin);

const IndependentPricing = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return (
        <Layout
            headerClass="bg-white"
            plans="text-primary"
            dropClass="btn-outline-primary"
        >

            <Banner />
            <SubPlans />
            <HealthPlans />
        </Layout>
    );
};
export default IndependentPricing;
