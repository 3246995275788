import React from "react";
import { Circles, RoundedSquares, Squares } from "../../components/icons";
import { DetailShapesPoint, SolutionPoint } from "../../components/points";
const Services = () => {
  const services = [

    {
      name: "Onboard and manage health ",
      text: "care seeking  member profiles",
      details:
        "Manage patients who are beneficiaries of",
      details2: "your company or  NGO. Manage ",
      details3: "appointments and requests.",
      icon: Circles,
    },
    {
      name: "Set and manage consultant",
      text: "schedules",
      details:
        "Manage doctor scheldules via the web and",
      details2: "mobile application. Manage patient appointments",
      details3: "appointments, booking requests",
      icon: RoundedSquares,
    },
    {
      name: "Easy outreach system ",
      text: "experience and loyalty",
      details: `Get discovered by members of the public `,
      details2: "and improve awareness and CSR status.",

      icon: Squares,
    },
  ];
  return (
    <div className="website_content mt-2 pt-3 ml-n1">
      <div className="w-100 mb-4 ml-n2">
        <SolutionPoint
          title="Non Governmental Organizations & Companies"
          body=" The platform allows companies and non governmental organizations or charities to extend free remote  medical support to its staff, members or the public."
          className="col-12 "
        />
      </div>
      <div className="flex-wrap d-justify-between d-align-start mb-5 ml-n4">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <DetailShapesPoint
              key={i + item.name}
              className={`col-12 col-sm-6 col-md-4  ${i === services.length - 1
                ? "border-after-none"
                : "border-after-md-grey"
                } ${i === 0 && "border-after-sm-grey"}`}
              title={item.name}
              body={item.details}
              subtitle={item.text}
              body2={item.details2}
              body3={item.details3}
              body4={item.details4}
              icon={
                <Icon fill="#fff" width="18px" height="18px" className="mb-2" />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
