import React from "react";
import Layout from "../../components/layout";

import Services from "./services";
import Screen from "./screen";
import HowItWorks from "./howItWorks";
import "./styles.scss";
import SolutionBanner from "../../components/solutionBanner/SolutionBanner";


const PharmacySolutions = () => {
  return (
    <Layout
    headerClass="bg-dark"
    solutions="text-white no-fade"
    dropClass="btn-outline-white"
    isDark
    >

  <SolutionBanner/>
      <Services />
      <HowItWorks />
      <Screen />

    </Layout>
  );
};
export default PharmacySolutions;
