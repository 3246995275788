import { observable, runInAction } from "mobx";
import { contentClient } from "../../Services/contentful-service";
export class TestKitsStore {
  @observable testKits = [];
  @observable dataCached = false;

  getTestKits = async () => {
    let response;
    await contentClient
      .getEntries({ content_type: "testKits" })
      .then((res) => {
        runInAction(() => {
          response = res.items;
          this.testKits = res.items;
          this.dataCached = true;
        });
      })
      .catch(console.error);
    console.log("response", response);
    return response;
  };
}
export default new TestKitsStore();
