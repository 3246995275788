import React, { useRef, useEffect } from "react";
import { AppButton } from "../../components/buttons";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { textIntro, textIntroTwo, play, apple, bannerPic } from "./animate";
import { ChevronDownThin } from "../../components/DBIcons";

const PricingBanner = ({ onScrollClick }) => {
  let intro = useRef(null);
  let introTwo = useRef(null);
  let playRef = useRef(null);
  let appleRef = useRef(null);
  let famRef = useRef(null);

  useEffect(() => {
    textIntro(intro);
    textIntroTwo(introTwo);
    play(playRef);
    apple(appleRef);
    bannerPic(famRef);
  }, []);

  return (
    <div className="pricing_section">
      <div className="bg-white pricing_section" style={{ marginBottom: "3rem" }}>
        <div className="d-justify-between d-align7start mt-2 flex-wrap mb-n5 website_content" style={{ height: "34rem" }}>
          <div className="text_a mt-5 pricing_text_container">
            <div className="col-12 col-md w-100 mt-3 flex-column d-justify-start d-align-start my-auto my-mob-5 mob-center">
              <div className="pricing_text_container" style={{ width: "100%" }}>
                <h1 className="text-black mb-2 mb-lg-0 inter text-center text-lg-left mobile-white w-100 h1-alt pricing_header_text" ref={(el) => (intro = el)} style={{ fontSize: "34px", width: "10%", marginTop: "5rem" }}>
                  Helping you manage your
                </h1>
                <h1 className="text-black mb-2 mt-n3 mb-lg-0 inter text-center text-lg-left mobile-white w-100 h1-alt pricing_header_text2" ref={(el) => (intro = el)} style={{ fontSize: "34px" }}>
                  practice more effectively
                </h1>
              </div>
              <div className="pricing_info_container">
                <p className="text-black pb-1 mb-5 mb-lg-2 pb-lg-1 aileron w-lg-50 mobile-light text-center text-lg-left" ref={(el) => (introTwo = el)}>
                  <span className="wrap wrap-md aileron pricing_text">tools to allow you manage your practice for onsite and</span> remote services
                </p>
              </div>
              <div className="d-justify-start d-align-start mb-lg-0 mb-0 pricing_button">
                <div ref={(el) => (playRef = el)} className="mr-2">
                  <AppButton icon={<FaApple width="30px" height="30px" fill="#fff" style={{ transform: "scale(2)" }} className="mr-1" />} text="Download on the" type="App Store" className="btn-dark" />
                </div>
                <div ref={(el) => (appleRef = el)}>
                  <AppButton icon={<FaApple width="30px" height="30px" fill="#fff" style={{ transform: "scale(2)" }} className="mr-1" />} text="Download on the" type="App Store" className="btn-dark" />
                </div>
              </div>
              <div className="w-fit mt-4 mobile mobile-light faded">
                <div>
                  Scroll below
                  <ChevronDownThin width="12px" height="12px" fill="#fff" className="ml-1" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mr-5 my-auto website_content " ref={(el) => (famRef = el)}>
            <div className="mr-5 screens_container pricing_image" style={{ marginLeft: "-10rem" }}>
              <img alt="screen" src={"https://res.cloudinary.com/olamilekan1/image/upload/v1621836052/Patient_screen.png"} className="mt-3 patient_screen pricing_patient_screen" />
              <img alt="screen" src={"https://res.cloudinary.com/olamilekan1/image/upload/v1621836043/Doctor_screen.png"} className="doctor_screen pricing_doctor_screen" />
              <img alt="screen" src={"https://res.cloudinary.com/olamilekan1/image/upload/v1621836067/web_screen.png"} className="web_screen pricing_web_screen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingBanner;