
import React, { useRef, useEffect } from "react";
import { AppButton } from "../../components/buttons";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { textIntro, textIntroTwo, play, apple, bannerPic } from "./animate";
import "./styles.scss";

const SolutionBanner = ({ onScrollClick }) => {
  let intro = useRef(null);
  let introTwo = useRef(null);
  let playRef = useRef(null);
  let appleRef = useRef(null);
  let famRef = useRef(null);
  useEffect(() => {
    textIntro(intro);
    textIntroTwo(introTwo);
    play(playRef);
    apple(appleRef);
    bannerPic(famRef);
  }, []);
  return (
    <div className="pharmacy_container">
     
      <div className="pharmacy_banner">
        <div className="pharmacy_content">
          <div className="pharmacy_div">

        
          <div className="pharmacy_headerText_container">
          <h1
                  className="text-white mb-2 pharmacy_headerText"
                  ref={(el) => (intro = el)}
                  // style={{ fontSize: "34px" }}
                >
                  Our Featured
                </h1>
          </div>

          
                <div className="pharmacy_featured_solution">
                <h1
                  className="text-white mt-n3 solution_headerText2"
                  ref={(el) => (intro = el)}
                 
                >


                  Solutions

                </h1>
                </div>
              
                <div className="pharmacy_unleash">
                  
                  <p
                  ref={(el) => (introTwo = el)}
                  className="text-white mt-n3 mb-2"
                >
               
                    Unleash the potential for prosperity in every journey to better  <br />
                    health. Health care access anytime,
                    anywhere, 24/7.
             
                </p>
             
                

              </div>
              </div>
                <div  className="mt-4 pharmacy_button">
                <div
                
                ref={(el) => (playRef = el)} 
                
                className=" mr-2 ">
                  <AppButton
                    icon={
                      <IoLogoGooglePlaystore
                        width="30px"
                        height="30px"
                        fill="#071232"
                        style={{ transform: "scale(1.6)" }}
                        className="mr-1"
                      />
                    }
                    text="Get it on"
                    type="Play Store"
                    className="btn-white button"
                  />
                </div>

                <div 
                
                ref={(el) => (appleRef = el)}
                
                
                >
                  <AppButton
                    icon={
                      <FaApple
                        width="30px"
                        height="30px"
                        fill="#071232"
                        style={{ transform: "scale(2)" }}
                        className="mr-1"
                      />
                    }
                    text="Download on the"
                    type="App Store"
                    className="btn-white button"
                  />
                </div>
              </div>
        </div>

        <div
      className=""
      ref={(el) => (famRef = el)}
        >

<img
          
          alt="screen"
          src={require('../../assets/images/thc_solution_image.png')}
      
         
          className="pharmacy_image_container"
       
        />
       
             
        </div>
      
      </div>
    </div>
  );
};

export default SolutionBanner;
