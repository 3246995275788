import React from "react";
import { observer, inject } from "mobx-react";
import "bootstrap/dist/css/bootstrap.min.css";
import { LoadingScreen } from "../src/website/components/Loadingpage";

// import DBHome from "./Components/DashBoard/DashBoardPages/DBHome";
import UserService from "./Services/user.service";
import "./scss/App.scss";
import Home from "./website/screens/home";

class App extends React.Component {
  
  render() {

    return (
      <div className="app">

        <Home />
      </div>
    );
  }
}

export default observer(App);
