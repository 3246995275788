/** @format */

import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import "./styles.scss";
import SideNav from "../sidenav";
import Section from "../planHeader";
const SectionPlan = ({
    children,
    headerClass,
    home,
    solutions,
    test,
    pricing,
    support,
    register,
    login,

    onClick,
    isDark,
}) => {
    const [toggled, setToggled] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [show]);
    return (
        <div
            className={`col-16 ml-n4 bg-white w-100  ${toggled && ""}`}
        >
            <div className="">
                <div className="">
                    <Section
                        className={headerClass}
                        home={home}
                        solutions={solutions}
                        test={test}
                        pricing={pricing}
                        support={support}
                        register={register}
                        login={login}

                        onClick={onClick}
                        isDark={isDark}
                        navClick={() => setShow((prev) => !prev)}
                        navClosed={show ? "ham_crossed" : ""}
                    />
                    {show ? (
                        <div
                            className="backdrop"
                            onClick={() => setShow((prev) => !prev)}
                        />
                    ) : null}
                </div>
            </div>

        </div>
    );
};

export default SectionPlan;
