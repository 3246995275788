import axios from "axios";

import history from "history/browser";
import Cookies from "js-cookie";
import { HOME } from "../router/router";
//https://cors-anywhere.herokuapp.com/
const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  getAuthUserContent(url) {
    let user = Cookies.get("access_token");

    return axios.get(API_URL + url, {
      headers: {
        Authorization: user ? `Bearer ${user}` : "",
      },
    });
  }
  getParamAuthUserContent(url, query, param, queryTwo, paramTwo) {
    let user = Cookies.get("access_token");

    return axios.get(API_URL + url, {
      headers: {
        Authorization: user ? `Bearer ${user}` : "",
      },
      params: { [query]: param, [queryTwo]: paramTwo },
    });
  }

  patchParamAuthUserContent(url, data, query, param, queryTwo, paramTwo) {
    let user = Cookies.get("access_token");

    return axios.patch(API_URL + url, data, {
      headers: {
        Authorization: user ? `Bearer ${user}` : "",
      },
      params: { [query]: param, [queryTwo]: paramTwo },
    });
  }

  getUserBoard(url) {
    return axios.get(API_URL + url);
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod",);
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin",);
  }
  async getHeader() {
    let user = await Cookies.get("access_token");

    if (user) {
      "Bearer " + user;
    } else {
      return {};
    }
  }
  async formPatch(data, url, handleError) {
    let user = await Cookies.get("access_token");

    return axios
      .patch(API_URL + url, data, {
        headers: {
          Authorization: user ? `Bearer ${user}` : "",
        },
      })
      .then(
        (response) => {
          if (response.data) {
            console.log("Im patch response: " + response.data);
            console.log(response);
            return response;
          }
        },
        (error) => {
          if (
            (error && error.response && error.response.status === 401) ||
            (error && error.response && error.response.status === 404) ||
            (error && error.response && error.response.status === 403) ||
            (error && error.response && error.response.status === 503)
          ) {
            history.push("/login");
            // window.location.reload();
          } else {
            const resMessage =
              error.response || error.message || error.toString();

            const errMsg =
              (resMessage &&
                resMessage.data &&
                resMessage.data.user &&
                resMessage.data.user.email) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.hospital &&
                resMessage.data.hospital[0]) ||
              (resMessage && resMessage.data && resMessage.data.hmo) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.length > 250 &&
                "An error was encountered, try again!") ||
              resMessage;


            handleError();
          }
        }
      );
  }

  async formSubmit(data, url, handleError) {
    let user = await Cookies.get("access_token");

    return axios
      .post(API_URL + url, data, {
        headers: {
          Authorization: user ? `Bearer ${user}` : "",
        },
      })
      .then(
        (response) => {
          if (response.data) {
            console.log("Form post response: " + response);
            return response;
          }
        },
        (error) => {
          if (
            (error && error.response && error.response.status === 401) ||
            (error && error.response && error.response.status === 404) ||
            (error && error.response && error.response.status === 403) ||
            (error && error.response && error.response.status === 503)
          ) {
            history.push("/login");
            // window.location.reload();
          } else {
            const resMessage =
              error.response || error.message || error.toString();

            const errMsg =
              (resMessage &&
                resMessage.data &&
                resMessage.data.user &&
                resMessage.data.user.email) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.hospital &&
                resMessage.data.hospital[0]) ||
              (resMessage && resMessage.data && resMessage.data.hmo) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.length > 250 &&
                "An error was encountered, try again!") ||
              resMessage;


            handleError();
          }
        }
      );
  }

  /***********************************Get User Info******************* */
  async getInfo(data, url, handleError) {
    let user = await Cookies.get("access_token");

    return axios
      .get(
        API_URL + url,

        {
          headers: {
            Authorization: user ? `Bearer ${user}` : "",
          },
        },
        data
      )
      .then(
        (response) => {
          if (response.data) {
            console.log("getInfo response: ");
            console.log(response);
            return response;
          }
        },
        (error) => {
          console.log(error.response);
          if (
            (error && error.response && error.response.status === 401) ||
            (error && error.response && error.response.status === 404) ||
            (error && error.response && error.response.status === 403) ||
            (error && error.response && error.response.status === 503)
          ) {
            history.push("/login");
            // window.location.reload();
          } else {
            const resMessage =
              error.response || error.message || error.toString();

            const errMsg =
              (resMessage &&
                resMessage.data &&
                resMessage.data.user &&
                resMessage.data.user.email) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.hospital &&
                resMessage.data.hospital[0]) ||
              (resMessage && resMessage.data && resMessage.data.hmo) ||
              (resMessage &&
                resMessage.data &&
                resMessage.data.length > 250 &&
                "An error was encountered, try again!") ||
              resMessage;


            handleError();
          }
        }
      );
  }
  /***********************************Delete User Info********************/
  async deleteInfo(url, alert, handleSuccess) {
    let user = await Cookies.get("access_token");

    return axios
      .delete(
        API_URL + url,

        {
          headers: {
            Authorization: user ? `Bearer ${user}` : "",
          },
        }
      )
      .then(
        (response) => {
          console.log("Delete success", response);
          if (response) {
            handleSuccess();
          }
        },
        (err) => {
          console.log("Delete error", err);
          if (err) {

            const errMsg =
              err && (err.response || err.message || err.toString() || err);
            let errorMsg =
              (errMsg && errMsg.data && errMsg.data.message) || errMsg;
            if (typeof errorMsg !== "string") {
              alert.show(
                "An error occured while processing delete. Please try again!",
                {
                  type: "error",
                }
              );
            } else {
              alert.show(errorMsg, {
                type: "error",
              });
            }
          }
        }
      );
  }
  //Get User

  getCurrentUser() {
    const user = Cookies.get("user");
    // JSON.parse(user);
    return user;
  }
  goHome() {
    this.props.history.push(HOME);
  }
}

export default new UserService();
