import React, { useEffect, useRef } from "react";
import { AboutUsPoint, Point } from "../../components/points";

import collaboration from "../../assets/images/collaboration.png"
import innovation from "../../assets/images/innovation.png"
import security from "../../assets/images/security.png"
import customer_centric from "../../assets/images/customer_centric.png"
import { scrollLists } from "./animate";

const Cards = ({ docImg }) => {
  let listOne = useRef(null);
  let listTwo = useRef(null);

  useEffect(() => {
    scrollLists(listOne);
    scrollLists(listTwo);
  }, []);

  return (
    <div className="ml-n2 website_content ">
      <div className=" col-10  
  ">
        <h3 style={{ fontSize: "26px", lineHeight: "2rem" }}>Our values are the driving force<br />
          behind everything we do at Total HealthCare</h3>

        <h3 style={{ fontSize: "22px", marginTop: "1rem" }}>Our values</h3>
      </div>

      <div className="flex-column ml-n4 flex-wrap mt-5 goal_section">
        <div className="flex-column flex-wrap ml-n4 px-1 px-lg-5">
          <div
            className="d-justify-between d-align-start flex-wrap mb-5 pt-4"
            ref={(el) => (listOne = el)}
          >


            <AboutUsPoint
              title="Innovation"
              image={innovation}
              text=" We are constantly pushing the boundaries of what is possible in the healthcare service industry and  committed to staying at the forefront of emerging technologies and trends."
            
              className="col-9 col-md-5"
            />


            <AboutUsPoint
              title="Trust and Security"
              image={security}
              text={"Trust is very important in digital health care. We take the safety of our clients' data and transactions very seriously. We use the latest security technology to keep our clients' information safe from internet threats and fraud.."}
              className="col-9 col-md-5"
            />
          </div>
          <div
            className="d-justify-between d-align-start flex-wrap-reverse mb-5 pt-4"
            ref={(el) => (listTwo = el)}
          >



            <AboutUsPoint
              image={collaboration}
              title="Collaboration"
              text="We work closely with our clients, partners, and other industry stakeholders to develop innovative solutions that meet the evolving needs of the healthcare industry.. "
              className="col-9 col-md-5 mb-lg-auto"
            />

            <AboutUsPoint
              image={customer_centric}
              title="Customer-centricity"
              text="Customer-centricity: Our clients are at the center of everything we do. We are dedicated to continually.meeting and exceeding our customers' needs by providing exceptional support and creating a positive experience."
              className="col-9 col-md-5"
            />
          </div>

        </div>

      </div>
    </div>
  );
};

export default Cards;
