import React from "react";
import Card from "../../components/cards/card";
import Register from "../../assets/images/register.png";
import Validate from "../../assets/images/validate.png";
import Update from "../../assets/images/update.png";
import Operate from "../../assets/images/operate.png";
import card_one from "../../assets/images/number_one.png"
import card_two from "../../assets/images/number_two.png"
import card_three from "../../assets/images/number_three.png"
import card_four from "../../assets/images/number_four.png"

const HowItWorks = () => {
  const services = [
    {
      name: "Register your practice",
      details:
        "Provide basic information about your practice including addresses, working hours, services offered, areas of specialization and upload practice licence",
      icon: Register,
      class: "card_register",
      digit: card_one
    },
    {
      name: "Account validated",
      details:
        "Within 24 hours, your profile gets validated and your  profile visibility and access enabled  to your existing  and new patients (upon request) on  mobile app platform",
      icon: Validate,
      class: "card_validate",
      digit: card_two
    },
    {
      name: "Update profile",
      details: `Modify profile if required and add  consultants, services rates and setup billing system`,
      icon: Update,
      class: "card_update",
      digit: card_three
    },
    {
      name: "Operate",
      details: `Operate your practce, track operational performance and review statistics and data`,
      icon: Operate,
      class: "card_operate",
      digit: card_four
    },
  ];
  return (
    <div className="website_content  mt-n3 pt-3">
      <h1 className="text-dark mb-3 inter text-x bold text-left ml-1 pl-3" style={{ fontSize: '28px' }}>How it works</h1>
      <div className="grid_third mb-5">

        {services.map((item, i) => {
          let icon = item.icon;
          let digit = item.digit
          return (

            <Card
              key={i + item.name}
              className=""
              title={item.name}
              body={item.details}
              src={icon}
              icon={digit}
              alt="items"
              imgClass={item.class}
            />
          );
        })}
      </div>
    </div>
  );
};
export default HowItWorks;
