import React, { useRef, useEffect } from "react";
import {
  Ambulance,
  Calender,
  Medic,
  Tele,
  Video,
} from "../../components/icons";
import { IconPoint } from "../../components/points";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const Services = () => {
  let servicesRef = useRef([]);
  servicesRef.current = [];
  useEffect(() => {
    servicesRef.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, opacity: 0 },
        {
          duration: 0.5,
          autoAlpha: 1,
          opacity: 1,
          ease: "none",
          translateY: -30,
          scrollTrigger: {
            trigger: el,
            //     start: "top center+=100",
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, []);
  const revealLists = (elem) => {
    if (elem && !servicesRef.current.includes(elem)) {
      servicesRef.current.push(elem);
    }
  };

  const services = [
    {
      name: "Medical records management ",
      details:
        "The platform offers a comprehensive medical records management system, encompassing patient files and electronic records issuance. It seamlessly integrates prescription management and the storage and accessibility of diagnostic test results. Say goodbye to paperwork and embrace seamless digital management.",
      icon: Medic,
    },
    {
      name: "Appointments and referrals",
      details:
        "Experience a hassle-free appointment management system with our platform. No more missed appointments! Our handy reminder function keeps you on track with email, SMS, or mobile app notifications. Plus, our robust referral system ensures smooth record exchange between healthcare providers.  Focus on your health and well-being while we take care of the rest.",
      icon: Calender,
    },
    {
      name: "Right health care access",
      details:
        "The platform provides health care seekers with clarity of options for healthcare including proximity and availability insight which allows the user make informed choice of healthcare.",
      icon: Medic,
    },
    {
      name: "Telemedicine",
      details:
        "Embrace the power of Telemedicine! Connect with health professionals and integrate with your diagnostic devices from the comfort of your home. Swift, seamless, and effective health assessments are just a click away.",
      icon: Tele,
    },
    {
      name: "Video Consultation",
      details: "Instant Access, Immediate Results! Connect with a certified specialist in minutes through our video consultation feature. Say goodbye to long hospital queues and delayed diagnosis. Get professional medical advice from your doctor or independent consultant, right on your mobile device. ",
      icon: Video,
    },
    {
      name: "Mobile app appointment booking",
      details:
        "Users can book and manage appointments from the mobile application. Quick, easy, and on your schedule.",
      icon: Tele,
    },

    {
      name: "Emergency Ambulance",
      details:
        "Book an ambulance from your health plan provider or one-off emergency request from available ambulance service providers",
      icon: Ambulance,
    },



    {
      name: "Medical Equipment Rental",
      details:
        "Equip and Empower Your Practice Anywhere: Flexible Medical Equipment and Office Rentals Tailored for the Independent Consultant and Doctor on the Move",
      icon: Medic,
    },
  ];
  return (
    <div className="website_content">
      <div className="flex-wrap d-justify-between d-align-start px-lg-5 px-1 mb-n1 padding_nulify">
        {services.map((item, i) => {
          let Icon = item.icon;
          return (
            <div
              ref={revealLists}
              key={i + item.name}
              className="col-12 col-md-6 col-xxl-4"
            >
              <IconPoint
                className=""
                iconClass="bg-primary"
                title={item.name}
                body={item.details}
                icon={<Icon fill="#fff" width="18px" height="18px" />}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Services;
