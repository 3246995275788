import React from "react";
import "./styles.scss";

import { AiOutlineArrowRight } from "react-icons/ai";
const IconPoint = ({
  icon,
  title,
  body,
  iconClass,
  className,
  arrow,
  onClick,
}) => {
  return (
    <div
      className={`d-justify-start d-align-start icon-point mb-4 pointer ${className}`}
      onClick={onClick}
    >
      <div className={`p-1 rounded-circle mr-1 d-center ${iconClass}`}>
        {icon}
      </div>
      <div className="flex-column d-justify-start d-align-start">
        <h3 className="text-dark mb-1 text-left inter text-x text-b">
          {title}
        </h3>
        <p className="text-light text-left aileron text-n">{body}</p>
        {arrow ? (
          <AiOutlineArrowRight
            width="24px"
            height="24px"
            fill="#2254D3"
            style={{ transform: "scale(1.2)" }}
            className="slide-icon"
          />
        ) : null}
      </div>
    </div>
  );
};
const AboutPoint = ({
  image,
  title,
  body,
  iconClass,
  className,
  arrow,
  onClick,
}) => {
  return (
    <div
      className={`d-justify-start d-align-start icon-point mb-4 pointer ${className}`}
      onClick={onClick}
    >


      <div className="flex-column d-justify-start d-align-start">
        <h3 className="text-dark mb-1 text-left inter text-x text-b">
          {title}
        </h3>
        <img src={image} style={{ width: "6rem", height: "4rem", marginBottom: "1rem" }} />
        <p className="text-light text-left aileron text-n">{body}</p>

      </div>
    </div>
  );
};
const Point = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  className,
  titleClass = "inter text-x bold",
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",

}) => {
  return (
    <div
      className={`flex-column d-justify-start d-align-start app-point glitch-in ${className}`}
      style={style}
    >
      <h3 className={`text-dark mb-1 text-left ${titleClass}`} style={style}>{title}</h3>
      <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
        marginTop: "-0.5em"
      }}>{subtitle}</h4>
      <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{text}</p>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{body}</p>

    </div>
  );
};


const AboutUsPoint = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  image,
  className,
  titleClass = "inter text-x bold",
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",

}) => {
  return (
    <div style={style} className={className}>


      <div
        className={`flex-column d-justify-start d-align-start app-point glitch-in `}

      >
        <img src={image} style={{ width: "4rem", height: "4rem", marginBottom: "1rem", paddingTop:"1rem" }} />
        <h3 className={`text-dark mb-1 text-left ${titleClass}`}>{title}</h3>
        <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
          marginTop: "-0.5em"
        }}>{subtitle}</h4>
        <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
        <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{text}</p>
        <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{body}</p>

      </div>
    </div>
  );
};
const AboutUsPointBody = ({
  icon,
  title,
  body,

  className,
  arrow,
  onClick,
}) => {
  return (
    <div
      className={`d-justify-start d-align-start icon-point mb-4 pointer ${className}`}
      onClick={onClick}
    >

      <div className="flex-column d-justify-start d-align-start">
        <h3 className="text-dark mb-1 text-left inter text-x text-b">
          {title}
        </h3>
        <p className="text-light text-left aileron text-n">{body}</p>

      </div>
    </div>
  );
};
const IndependentPoint = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  className,
  titleClass = "inter text-x bold",
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",
}) => {
  return (
    <div
      className={`flex-column d-justify-start mt-2 d-align-start ml-n4 app-point glitch-in ${className}`}
      style={style}
    >
      <h3 className={`text-dark mb-1 text-left ${titleClass}`} style={{ fontSize: "26px" }}>{title}</h3>
      <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
        marginTop: "-0.5em",
      }}>{subtitle}</h4>
      <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{text}</p>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{body}</p>
    </div>
  );
};



const SolutionPoint = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  titleStyle,
  className,
  titleClass = "inter text-x bold",
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",
  marginLeft
}) => {
  return (
    <div
      className={`flex-column d-justify-start d-align-start mb-2 ml-n4 app-point glitch-in ${className}`}
      style={style}
    >
      <h1 className={`text-dark mb-1 inter text-left font-size-lg${titleClass} ${titleStyle}`} style={{ fontSize: '28px', marginLeft:   marginLeft }} >{title}</h1>
      <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
        marginTop: "-1em",
      }}>{subtitle}</h4>
      <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
      <p className={`text-light text-left aileron text-n ${titleStyle}`} style={{ marginLeft: "0.5rem" }}>{text}</p>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" ,fontSize: "1rem"}}>{body}</p>
    </div>
  );
};

const TestKitPoint = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  titleStyle,
  className,
  titleClass ,
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",
}) => {
  return (
    <div
      className={`${className}`}
      style={style}
    >
      <h1 className={`${className}`} 
      >{title}</h1>
      <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
        marginTop: "-1rem",
      }}>{subtitle}</h4>
      <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
      <p className={`text-light text-left aileron text-n ${titleStyle}`}>{text}</p>
      <p className={`${titleClass}`}>{body}</p>
    </div>
  );
};
const TextPoint = ({
  title,
  subtitle,
  body,
  className,
  titleClass = "text-n inter text-b mb-4 text-dark",
}) => {
  return (
    <div
      className={`flex-column d-justify-start d-align-start app-point ${className}`}
    >
      <p className={titleClass}>{title}</p>
      <p className="text-n aileron text-light">
        {body.split("\n").map((str, i) => {
          return (
            <p
              style={{ marginBottom: str == "" ? "30px" : "" }}
              key={i + "str"}
            >
              {str}
            </p>
          );
        })}
      </p>
    </div>
  );
};

const ShapesPoint = ({ title, body, className, icon, body2, body3, textClassName }) => {
  return (
    <div
      className={` ${className}`}
    >
      
      {icon}
      <h3 className="text-dark mb-1 text-left inter text-20">{title}</h3>
      <div className={`${textClassName}`}>
    
      <p className="text-light text-left aileron text-n">{body}</p>
      <p className="text-light text-left aileron text-n">{body2}</p>
      <p className="text-light text-left aileron text-n">{body3}</p>
      </div>
     
    </div>
  );
};
const IndependentShapePoint = ({
  title,
  subtitle,
  text,
  style,
  body,
  topic,
  className,
  titleClass = "inter text-x bold",
  subtitleClass = "inter text-x bold",
  topicClass = "inter text-x bold",
}) => {
  return (
    <div
      className={`flex-column d-justify-start d-align-start ml-n4 app-point glitch-in ${className}`}
      style={style}
    >
      <h3 className={`text-dark mb-1 text-left ${titleClass}`}>{title}</h3>
      <h4 className={`text-dark mb-1 text-left ${subtitleClass}`} style={{
        marginTop: "-0.5em", marginLeft: "1rem",
      }}>{subtitle}</h4>
      <h5 className={`text-dark mb-1 text-left ${topicClass}`}>{topic}</h5>
      <p className="text-light text-left aileron text-n" style={{ marginLeft: "0.5rem" }}>{text}</p>
      <p className="text-light text-left ml-4 aileron text-n" style={{ marginTop: "-1rem" }} >{body}</p>
    </div>
  );
};
const DetailShapesPoint = ({ title, body, className, icon, body2, body3, subtitle, body4 }) => {
  return (
    <div
      className={`flex-column d-justify-start mb-5s d-align-start position-relative ${className}`}

    >
      <div>
        {icon}
      </div>

      <h3 className="text-dark text-left inter mt-1 text-20" >{title}</h3>
      <h4 className="text-dark mt-n3 text-left  inter text-20">{subtitle}</h4>
      <p className="text-light mt-n2 text-left aileron text-n">{body}</p>
      <p className="text-light text-left aileron text-n" style={{ marginTop: "-0.7rem" }}>{body2}</p>
      <p className="text-light text-left aileron text-n" style={{ marginTop: "-0.7rem" }}>{body3}</p>
      <p className="text-light text-left aileron text-n" style={{ marginTop: "-0.7rem" }}>{body4}</p>
    </div>
  );
};
export { IconPoint, Point, ShapesPoint, TextPoint, IndependentPoint, SolutionPoint,TestKitPoint, DetailShapesPoint, IndependentShapePoint, AboutUsPoint };









