import React from "react";
import { IndependentShapePoint } from "../../components/points";
import IndScreen from "../../assets/images/independent_screen.png";

const Screen = () => {
  const services = [
    {
      name: "📝 Sign up as a Provider and create your profile",
      details: "👀 Allow patients to view the services you offer on the patient app.",
    },
    {
      name: "✅ Verify your Credentials ",
      details: "Your credentials verified and potential patients alike.",
    },
    {
      name: "🤝 Access clients and attract new patients",
      details:
        "💼 Manage and receive patient referrals from various sources including individual consultant, laboratories, and hospitals, expanding your network and reaching a broader audience. Set and manage consultancy fees for remote consultations.",
    },
    {
      name: "📂 Consult and efficiently manage records  ",
      details:
        "Manage patients records digitally and access their existing records for comprehensive care.",
    },
  ];

  return (
    <div className="ml-2 mt-5 pt-3 d-center mt-3" style={{ marginTop: "3rem" }}>
      <div className="w-fit col-12 col-lg-8 col-xl-7 mb-4 px-4">
        <div className="flex flex-row d-flex w-fit d-align-start d-justify-between mt-n5 ">
          <img
            alt="independent consultant"
            src={IndScreen}
            className="independent_screen mr-2 mr-md-3 mr-lg-5"
          />

          <div className="flex-column d-justify-start ml- d-align-start  " style={{ marginTop: "3rem" }}>
            {services.map((item, i) => {
              return (
                <IndependentShapePoint
                  key={i + item.name}
                  className="mb-1"
                  title={item.name}
                  body={item.details}
                />
              );
            })}
            <p className="text-light text-left aileron text-n" style={{ marginTop: "7rem", marginLeft: "-1rem" }}>
              Join our platform today and unlock the full potential of your healthcare practice!
            </p>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Screen;