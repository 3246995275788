import React, { useEffect, useRef } from "react";
import TextCard from "../../components/cards/textCard";
import { scrollCards } from "./animate";
const Cards = () => {
  let cardOneRef = useRef(null);
  let cardTwoRef = useRef(null);
  useEffect(() => {
    scrollCards(cardOneRef, "card_one", -20, 0);
    scrollCards(cardTwoRef, "card_two", 20, 1);
  }, []);
  return (
    <div className="d-justify-between d-align-start flex-wrap mb-5 website_content padding_nulify">
      <div className="col-12 col-md-6" ref={(el) => (cardOneRef = el)}>
        <TextCard
          className="bg-primary mb-3 mb-lg-0"
          tag="How Total HealthCare helps"
          title="Total HealthCare helps clinicians and patients through it’s dedicated applications."
        />
      </div>
      <div className="col-12 col-md-6" ref={(el) => (cardTwoRef = el)}>
        <TextCard
          className="bg-purple ml-auto"
          tag="Who it helps"
          title="Built to personalise, and better measure patient care and treatment. "
        />
      </div>
    </div>
  );
};

export default Cards;
