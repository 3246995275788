
import React, { useEffect, useState } from "react";
import { AppButton } from "../../components/buttons";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";
import { textIntro, textIntroTwo, play, apple, bannerPic } from "./animate";
import { ChevronDownThin } from "../../components/DBIcons";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = ({ onScrollClick, src, onLoad, onError }) => {
  useEffect(() => {
    textIntro();
    textIntroTwo();
    play();
    apple();
    bannerPic();
  }, []);

  return (
    <div style={{ backgroundColor: "#DDE8FC", height: "22rem" }}>


      <div className="d-justify-between ml-n3  mt-5    website_content  ">
        <div className="ml-2 mt-5 w-100 ">
          <p style={{ marginTop: "3.5rem", fontSize: "16px" }}>
            About Us
          </p>
          <div className="about_content">


            <h3
              className="text-dark mb-2  mb-lg-0  text-lg-left mobile-white w-100 about_us_Text"
              style={{ fontSize: "32px" }}
            >
              We are a team of passionate professionals
            </h3>
            <h3
              className="text-dark mb-2  mb-lg-0 mt-2  text-lg-left mobile-white w-100 about_us_Text"
              style={{ fontSize: "32px" }}
            >

              dedicated to revolutionising health care
            </h3>
            <h3
              className="text-dark mb-2  mb-lg-0 mt-2  text-lg-left mobile-white w-100 about_us_Text"
              style={{ fontSize: "32px" }}
            >

              service  and access to health care
            </h3>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Banner;