import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout";
import Banner from "./banner";
import Blogs from "./blogs";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import "./styles.scss";
gsap.registerPlugin(ScrollToPlugin);

const Home = (props) => {
  let bodyRef = useRef(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const scrollTo = (el) => {
    gsap.to(window, { duration: 1, scrollTo: el });
  };
  return (
    <Layout
      headerClass="bg-white"
      blog="text-primary"
      dropClass="btn-outline-primary"
    >
      <Banner onScrollClick={() => scrollTo(bodyRef)} props={props} />
      <div className="pt-5 pt-lg-0">
        <Blogs />
      </div>
    </Layout>
  );
};
export default Home;
