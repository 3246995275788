

import React from "react";


const Middle = () => {
  

    return (
        <div className="ml-n1 website_content mt-3  footer_section" 
        style={{ backgroundColor: "#f0e0b1", height: "15rem", marginBottom: "5rem" }}
        >
          <div className="flex-wrap ml-1 d-justify-between pt-5 flex-row d-align-center   "
          
          
          >
           
    <div  style={{
      marginRight: "-4rem"
    }}>
    
   
                <p className="text-light aileron text-n"  style={{
                    fontSize: "0.9rem"
                }}>   Founder & CEO</p>
             
    </div>
    <div>
    
    </div>
    <div className="text-light aileron text-n "  style={{width: "80%", marginTop: "1rem" }}>
 
     <h3 className="text-dark mb-1  inter text-x text-b" style={{  marginLeft: "3rem", marginRight: "0.8rem" }}>
     "Our goal is simple: To provide healthcare service providers and individuals with reliable healthcare service operations management and access solutions, without any unnecessary hassle."
                </h3>
    </div>
          </div>
        </div>
      )
};

export default Middle;