import React, { useEffect, useRef } from "react";
import BlogItem from "../../components/blogItem";
import BlogStore from "../../stores/blogStore";
import { Link } from "react-router-dom";
const Blogs = () => {
  const items = BlogStore.blogItems;

  return (
    <div className="website_content">
      <section className="flex-wrap d-justify-start d-align-start">
        {items.map((item, i) => {
          if (i !== 0) {
            return (
              <Link
                className="mb-3 col-12 col-md-6 col-xxl-4"
                key={i + "item" + i}
                to={`/blog/${item.title + i}`}
              >
                <BlogItem
                  src={item.image}
                  time={item.date}
                  title={item.title}
                  body={item.body}
                  author={item.author}
                />
              </Link>
            );
          }
        })}
      </section>
    </div>
  );
};
export default Blogs;
