import React, { useEffect, useRef } from "react";
import { Point } from "../../components/points";
import Doctor from "../../assets/images/doctor.jpg";
import Medication from "../../assets/images/medication.jpg";
import Personal from "../../assets/images/personal.jpg";
import Reminder from "../../assets/images/reminder.jpg";
import RightTime from "../../assets/images/rightTime.png";
import { scrollLists } from "./animate";

const Cards = ({ docImg , docImg3,docImg4}) => {
  let listOne = useRef(null);
  let listTwo = useRef(null);
  let listThree = useRef(null);
  let listFour = useRef(null);

  useEffect(() => {
    scrollLists(listOne);
    scrollLists(listTwo);
    scrollLists(listThree);
    scrollLists(listFour);
  }, []);

  return (
    <div className="website_content padding_nulify">
      <div className="flex-column flex-wrap mt-5 pt-5 home-body">
        <div className="flex-column flex-wrap px-1 px-lg-5">
          <div
            className="d-justify-between d-align-start flex-wrap mb-5 pt-4"
            ref={(el) => (listOne = el)}
          >
            <img
              className="col-12 col-md-6 mb-lg-0 mb-3"
              alt="doctor"
              src={docImg}
              style={{
    borderRadius: '1rem',
    height: "17rem"
    
  }}
            />
            <Point
              title="Helping"
              subtitle="doctors prescribe right medication"
              body="🩺 Your Doctor’s New Best Friend: Leverage our rich case databases and libraries for accurate diagnoses and precise prescriptions."
              className="col-12 col-md-6"
            />
          </div>
          <div
            className="d-justify-between d-align-start flex-wrap-reverse mb-5 pt-4"
            ref={(el) => (listTwo = el)}
          >
            <Point
              title="Reminding"
              subtitle="patients of their appointments and access"
              topic="to medical record"
              body="⏰ Your Personal Health Companion: Forget about missed appointments! Get timely reminders via email, text, or through our mobile app. Your health record access? Just a click away!."
              className="col-12 col-md-6 mb-lg-auto"
            />
            <img
              className="col-12 col-md-6 mb-lg-0 mb-3"
              alt="reminder"
              src={Reminder}
              
            />
          </div>

          <div
            className="d-justify-between d-align-start flex-wrap mb-5 pt-4"
            ref={(el) => (listThree = el)}
          >
           <img
  className="col-12 col-md-6 mb-lg-0 mb-3"
  alt="personalize"
  src={docImg3}
  style={{
    borderRadius: '1rem',
    height: "17rem"
    
  }}
/>
            <Point
              title="Personalized"
              subtitle="recommendations on treatment and"
              topic=" medication"
              text="💊 Personalized Treatment at Your Fingertips: Discover bespoke recommendations for your care. Speak to certified specialists within minutes, all from the comfort of your device."
              body="🚀 Rapid Care, No Queues: Ditch the hospital lines. Get on-the-spot video consultations with a doctor and expedite your diagnosis and treatment."
              className="col-12 col-md-6"
            />



          </div>
          <div
            className="d-justify-between d-align-start flex-wrap-reverse mb-5 pt-4"
            ref={(el) => (listFour = el)}
            style={{ overflow: 'hidden' }} 
          >
            <Point
              title="Right"
              subtitle="medication, at the right time"
              body="💊 Request prescription medication from your choice of 
              pharmacy and have it delievered to your doorstep."
              className="col-12 col-md-6 mb-lg-auto"
            />
            <img
  className="col-12 col-md-6 mb-lg-0 mb-3"
  alt="personalize"
  src={docImg4}
  style={{
    borderRadius: '1rem',
    height: "17rem"
  }}
/>
          </div>
        </div>
        {/* <div className="bg-primary-light px-1 px-lg-5 py-4 mt-5">
          <div className="px-4">
            <div className="d-justify-between d-align-start flex-wrap mb-5 pt-4 px-0 px-lg-5">
              <Point
                title="Right medication, at the right time"
                body="Speak to a certified specialist within minutes.
              Get video consultation with a doctor on your mobile device, avoid hospital queues and delay diagnosis and treatment."
                className="col-12 col-md-6"
              />
              <img
                className="col-12 col-md-6"
                alt="medication"
                src={RightTime}
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Cards;
